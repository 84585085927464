import { SvgIcon, SvgIconProps } from '@mui/material';

function DocumentIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M9.375 19.6874V2.8125L11.25 3.75L13.125 2.8125L14.9965 3.75L16.8931 2.8125L18.75 3.75L20.6127 2.8125L22.4836 3.75L24.375 2.8125L26.2505 3.75L28.1249 2.8125V15.9375'
        strokeLinejoin='round'
      />
      <path
        d='M28.1254 15.9297V22.4922C28.1254 23.7354 27.6316 24.9276 26.7525 25.8067C25.8734 26.6858 24.6812 27.1797 23.438 27.1797V27.1797C22.1948 27.1797 21.0025 26.6858 20.1234 25.8067C19.2443 24.9276 18.7505 23.7354 18.7505 22.4922V19.6797H2.81302C2.6896 19.6786 2.5672 19.7021 2.45297 19.7488C2.33873 19.7955 2.23495 19.8645 2.14767 19.9518C2.0604 20.0391 1.99138 20.1429 1.94466 20.2571C1.89793 20.3714 1.87443 20.4938 1.87552 20.6172C1.87552 24.3672 2.27045 27.1797 6.56301 27.1797H23.438'
        strokeLinejoin='round'
      />
      <path d='M13.1245 8.4375H24.3745' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.8755 13.1172H24.3755' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  );
}
DocumentIcon.defaultProps = {
  viewBox: '0 0 30 30'
};
export default DocumentIcon;
