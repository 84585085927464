import { SvgIcon, SvgIconProps } from '@mui/material';

function ShieldOutlinedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M27.1352 6.58425C21.8956 5.64439 19.7294 4.94828 15.0002 2.8125C10.271 4.94828 8.10474 5.64439 2.86518 6.58425C1.91595 21.6291 14.0966 26.8241 15.0002 27.188C15.9037 26.8241 28.0844 21.6291 27.1352 6.58425Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M15.0002 2.8125C10.271 4.94828 8.10474 5.64439 2.86518 6.58425C1.91595 21.6291 14.0966 26.8241 15.0002 27.188V2.8125Z' />
    </SvgIcon>
  );
}
ShieldOutlinedIcon.defaultProps = {
  viewBox: '0 0 30 30'
};

export default ShieldOutlinedIcon;
