import React, { Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
// @ld
import { Loader } from '@ld/core';
// @custom
import Logo from '../logo';
import Footer from '../footer';
import useRedirectToApp from './use-redirect-to-app';
import { AuthContainer, ShapesBackground, Main, Card } from './styles';

const AuthActionLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthContainer>
      <ShapesBackground />
      <Main $isCentered>
        <Logo height={40} />
        <Card>{children}</Card>
      </Main>
      <Footer />
    </AuthContainer>
  );
};

const Fallback = () => (
  <Box display='flex' alignItems='center' justifyContent='center' p={3}>
    <Loader />
  </Box>
);

const AuthActionLayoutRoute = ({
  component: Component,
  location,
  children,
  ...rest
}: RouteProps & { children?: React.ReactNode }) => {
  useRedirectToApp();
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthActionLayout>
          <Suspense fallback={<Fallback />}>
            {Component ? <Component {...props} /> : children}
          </Suspense>
        </AuthActionLayout>
      )}
    />
  );
};

export default AuthActionLayoutRoute;
