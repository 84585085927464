import { SvgIcon, SvgIconProps } from '@mui/material';

function FamilyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M57.1987 33.5392C56.6035 26.3474 51.3662 22.0342 45.4613 22.0342C40.5494 22.0342 37.0883 25.1633 35.0823 28.7775L33.6172 30.9405V48.0132L35.0823 50.4273L47.2887 50.6879L57.1987 33.5392Z'
        fill='#EE6338'
      />
      <path
        d='M24.7039 22.0342C18.8169 22.0342 13.5635 26.3265 12.9666 33.5392L23.0737 50.1709L35.0829 50.4272V28.7775C33.077 25.1633 29.6145 22.0342 24.7039 22.0342Z'
        fill='#4BABB3'
      />
      <path
        d='M35.0812 42.3311C29.7751 42.3311 25.1462 45.5546 23.072 50.1719L24.0441 51.7716C26.6317 54.0074 29.6188 56.5712 32.3513 58.8943C33.294 59.6959 34.1795 60.0069 35.0812 60.0002C36.2089 59.9918 37.3622 59.4755 38.6897 58.6711C41.2203 56.4805 44.3939 53.6426 46.9056 51.35L47.2876 50.6888C45.3356 45.8002 40.58 42.3311 35.0812 42.3311Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M38.691 58.6711C41.2217 56.4805 44.3953 53.6426 46.907 51.35L47.289 50.6889C45.3369 45.8002 40.5813 42.3311 35.0825 42.3311V60.0003C36.2102 59.9919 37.3636 59.4756 38.691 58.6711Z'
        fill='#313842'
      />
      <path
        d='M51.1992 35.5002C51.1992 38.4791 49.5294 41.1289 43.2397 46.8435C42.0244 47.9482 41.9146 49.8983 43.1196 51.1002C44.1164 52.097 45.7647 52.1586 46.9072 51.3498C54.1636 44.7274 57.3073 40.5985 57.3073 34.7676C57.3073 34.3443 57.2331 33.9505 57.1991 33.54H52.6644C51.8553 33.54 51.1993 34.196 51.1993 35.0052V35.5002H51.1992Z'
        fill='#FEF3EF'
      />
      <path
        d='M21.8967 21.8187C18.6645 21.8187 16.0361 19.1904 16.0361 15.9582C16.0361 12.726 18.6645 10 21.8967 10C25.1288 10 27.7572 12.726 27.7572 15.9582C27.7572 19.1904 25.1288 21.8187 21.8967 21.8187Z'
        fill='#F1F8F9'
      />
      <path
        d='M48.2675 21.8187C45.0353 21.8187 42.407 19.1904 42.407 15.9582C42.407 12.726 45.0353 10 48.2675 10C51.4997 10 54.128 12.726 54.128 15.9582C54.128 19.1904 51.4997 21.8187 48.2675 21.8187Z'
        fill='#FEF3EF'
      />
      <path
        opacity='0.05'
        d='M51.1994 35.5002C51.1994 38.4791 49.5297 41.1289 43.24 46.8435C42.0247 47.9482 41.9149 49.8983 43.1198 51.1002C44.1167 52.097 45.765 52.1586 46.9075 51.3498C54.1639 44.7274 57.3076 40.5985 57.3076 34.7676C57.3076 34.3443 57.2333 33.9505 57.1994 33.54H52.6647C51.8555 33.54 51.1995 34.196 51.1995 35.0052V35.5002H51.1994Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M48.2675 21.8187C45.0353 21.8187 42.407 19.1904 42.407 15.9582C42.407 12.726 45.0353 10 48.2675 10C51.4997 10 54.128 12.726 54.128 15.9582C54.128 19.1904 51.4997 21.8187 48.2675 21.8187Z'
        fill='#313842'
      />
      <path
        d='M24.0445 51.7714C25.0743 52.0956 26.2642 51.8809 27.0449 51.1001C28.2499 49.8982 28.1401 47.9481 26.9248 46.8435C20.635 41.1289 18.9653 38.4791 18.9653 35.5002V35.0052C18.9653 34.196 18.3093 33.54 17.5002 33.54H12.9654C12.9314 33.9505 12.8572 34.3443 12.8572 34.7676C12.8572 41.1588 17.1914 45.8512 24.0445 51.7714Z'
        fill='#F1F8F9'
      />
      <path
        opacity='0.05'
        d='M21.8954 21.8187C18.6633 21.8187 16.0349 19.1904 16.0349 15.9582C16.0349 12.726 18.6633 10 21.8954 10C25.1276 10 27.756 12.726 27.756 15.9582C27.756 19.1904 25.1276 21.8187 21.8954 21.8187Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M24.0445 51.7714C25.0743 52.0956 26.2642 51.8809 27.0449 51.1001C28.2499 49.8982 28.1401 47.9481 26.9248 46.8435C20.635 41.1289 18.9653 38.4791 18.9653 35.5002V35.0052C18.9653 34.196 18.3093 33.54 17.5002 33.54H12.9654C12.9314 33.9505 12.8572 34.3443 12.8572 34.7676C12.8572 41.1588 17.1914 45.8512 24.0445 51.7714Z'
        fill='#313842'
      />
      <path
        d='M35.0836 42.8317C37.7985 42.8317 39.9994 40.6308 39.9994 37.9158C39.9994 35.2009 37.7985 33 35.0836 33C32.3686 33 30.1677 35.2009 30.1677 37.9158C30.1677 40.6308 32.3686 42.8317 35.0836 42.8317Z'
        fill='white'
      />
    </SvgIcon>
  );
}

FamilyIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default FamilyIcon;
