import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

// @custom
import { StyledFolder } from './styles';

const FolderSkeleton = () => {
  return (
    <StyledFolder to=''>
      <Skeleton variant='circular' width={40} height={40} />
      <Box my={2}>
        <Skeleton variant='text' width='70%' height={20} />
        <Skeleton variant='text' width='50%' height={20} />
      </Box>
      <Skeleton variant='text' width='100%' height={5} />
    </StyledFolder>
  );
};

export default FolderSkeleton;
