import { Timestamp } from 'firebase/firestore';
// @custom
import { IEventAtRead } from './event';
import { TNullableString } from './base';
import { EProfileType } from './profiles';

export type TPaymentMethod = {
  id: string;
  cardType: string;
  lastFourDigits: string;
  brand: string;
  authorizationCode: string;
  cardExpYear: string;
  cardExpMonth: string;
  signature: string;
  currency: string;
  countryCode: string;
  customerCode: string;
  email: string;
};

export enum ESubscriptionStatus {
  // The subscription is currently active, and will be charged on the next payment date
  ACTIVE = 'active',
  // The subscription has been cancelled, and we'll no longer attempt to charge the card on the subscription
  CANCELLED = 'cancelled',
  // The subscription is currently active, but we won't be charging it on the next payment date
  NON_RENEWING = 'non-renewing',
  // The subscription is still active, but there was an issue while trying to charge the customer's card
  ATTENTION_REQUIRED = 'attention-required'
}

export type TSubscriptionRead = {
  id: string;
  profile: {
    id: string;
    name: string;
    type: EProfileType;
    billingUserId: string;
    email: TNullableString;
  };
  paymentMethodId: string;
  nextPaymentDate: Timestamp;
  status: ESubscriptionStatus;
  created: IEventAtRead;
  updated: IEventAtRead;
};
