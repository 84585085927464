import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// @custom
import Modal from '../modal';
import Loader from '../loader';
import { BodySmallText } from '../typography';

type DialogProps = {
  id: string;
  title: string;
  open: boolean;
  children?: React.ReactNode;
  message?: string | React.ReactNode;
  isCancelDisabled?: boolean;
  isConfirmDisabled?: boolean;
  isLoading?: boolean;
  cancelText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const Dialog = ({
  id,
  open,
  title,
  message,
  onCancel,
  onConfirm,
  children,
  isLoading = false,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  isCancelDisabled = false,
  isConfirmDisabled = false
}: DialogProps) => {
  return (
    <Modal id={id} title={title} open={open} hideCancelButton onClose={onCancel}>
      {children || <BodySmallText>{message}</BodySmallText>}
      <Box py={2} display='flex' justifyContent='space-between'>
        <Button
          color='inherit'
          variant='outlined'
          onClick={onCancel}
          disabled={isLoading || isCancelDisabled}
        >
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          disabled={isLoading || isConfirmDisabled}
          id={`action-${confirmText}`}
        >
          {isLoading ? <Loader /> : confirmText}
        </Button>
      </Box>
    </Modal>
  );
};

export default Dialog;
