import * as React from 'react';
import { FirestoreError } from 'firebase/firestore';
// @ld
import { PROFILES, IProfileRead } from '@ld/utils';
// @custom
import useGetItem from 'api/utils/use-get-item';
import useDocListener from 'api/utils/use-doc-listener';

const ProfileContext = React.createContext<{
  profileId: string;
  profile?: IProfileRead;
  error: FirestoreError | null;
  setProfileId: (profileId: string) => void;
}>({ profileId: '', error: null, setProfileId: () => null });

export const ProfileProvider = ({ children }: { children: React.ReactNode }) => {
  const [profileId, setProfileId] = React.useState('');
  const [profile, setProfile] = React.useState<IProfileRead>();

  const { error } = useGetItem<IProfileRead>({
    id: profileId,
    enabled: !!profileId,
    collection: PROFILES,
    onSuccess: (data) => setProfile(data)
  });

  useDocListener<IProfileRead>({
    id: profile?.id,
    collection: PROFILES,
    enabled: !!profile?.id,
    onChange: (data) => setProfile(data)
  });

  React.useEffect(() => {
    if (!profileId) {
      setProfile(undefined);
    }
  }, [profileId]);

  return (
    <ProfileContext.Provider value={{ profile, profileId, error, setProfileId }}>
      {children}
    </ProfileContext.Provider>
  );
};

ProfileContext.displayName = 'ProfileContext';

export default ProfileContext;
