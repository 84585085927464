// top-level collections
export const TASKS = 'tasks';
export const PROMO_CODES = 'promo_codes';
export const SHARE_LINKS = 'share_links';
export const ORGANISATIONS = 'organisations';
export const PROFILE_INVITES = 'profile_invites';

export const ERS_GAP = 'ers_gap';
export const DETAILS = 'details';
export const ENTITIES = 'entities';
export const SETTINGS = 'settings';
export const CONTACTS = 'contacts';
export const VAULT_CONFIG = 'vault_config';
export const VAULT_STATUS = 'vault_status';
export const NOTIFICATIONS = 'notifications';
export const SUBSCRIPTIONS = 'subscriptions';
export const PAYMENT_METHODS = 'payment_methods';
export const EMERGENCY_CONTACTS = 'emergency_contacts';

// helper functions
const getSubCollectionPath = (documentPath: string, subCollection: string) =>
  `${documentPath}/${subCollection}`;

// categories
export const CATEGORIES = 'categories';

export const DOCUMENTS = 'documents';

export const getCategoryDocumentsPath = (categoryId: string) =>
  `${CATEGORIES}/${categoryId}/${DOCUMENTS}`;

// users
export const USERS = 'users';

export const getUserDocPath = (uid: string) => `${USERS}/${uid}`;

const getUserSubCollectionPath = (uid: string, collection: string) =>
  getSubCollectionPath(getUserDocPath(uid), collection);

export const getUserSettingsPath = (uid: string) =>
  getUserSubCollectionPath(uid, SETTINGS);

export const getUserSubscriptionsPath = (uid: string) =>
  getUserSubCollectionPath(uid, SUBSCRIPTIONS);

export const getUserPaymentMethodsPath = (uid: string) =>
  getUserSubCollectionPath(uid, PAYMENT_METHODS);

export const getUserEmergencyContactsPath = (uid: string) =>
  getUserSubCollectionPath(uid, EMERGENCY_CONTACTS);

// profiles
export const PROFILES = 'profiles';
export const ROLES = 'roles';
export const SUMMARY = 'summary';

export const getProfileDocPath = (profileId: string) => `${PROFILES}/${profileId}`;

const getProfileSubCollectionPath = (profileId: string, collection: string) =>
  getSubCollectionPath(getProfileDocPath(profileId), collection);

export const getProfileDetailsPath = (profileId: string) =>
  getProfileSubCollectionPath(profileId, DETAILS);

export const getProfileERSGapPath = (profileId: string) =>
  getProfileSubCollectionPath(profileId, ERS_GAP);

export const getProfileRolesPath = (profileId: string) =>
  getProfileSubCollectionPath(profileId, ROLES);

export const getProfileContactsPath = (profileId: string) =>
  getProfileSubCollectionPath(profileId, CONTACTS);

export const getProfileContactsUniqueEmailsPath = (profileId: string) =>
  getProfileSubCollectionPath(profileId, 'contacts_unique_emails');

export const getProfileNotificationsPath = (profileId: string) =>
  getProfileSubCollectionPath(profileId, NOTIFICATIONS);

export const getProfileSummaryCollectionPath = (profileId: string) =>
  `${getProfileDocPath(profileId)}/${SUMMARY}`;

export const getProfileSummaryPath = (profileId: string) =>
  `${getProfileDocPath(profileId)}/${SUMMARY}/${profileId}`;
// profile vault
export const getProfileCategoriesPath = (profileId: string) =>
  getProfileSubCollectionPath(profileId, CATEGORIES);

export const getProfileCategoriesDocPath = (profileId: string, categoryId: string) =>
  `${getProfileCategoriesPath(profileId)}/${categoryId}`;

export const getProfileEntitiesPath = ({
  profileId,
  categoryId
}: {
  profileId: string;
  categoryId: string;
}) => `${getProfileCategoriesPath(profileId)}/${categoryId}/${ENTITIES}`;

export const getProfileDocumentsPath = ({
  profileId,
  categoryId,
  entityId
}: {
  entityId?: string;
  profileId: string;
  categoryId: string;
}) => {
  let basePath = `${getProfileCategoriesPath(profileId)}/${categoryId}`;

  if (entityId) {
    basePath = `${basePath}/${ENTITIES}/${entityId}`;
  }

  return getSubCollectionPath(basePath, DOCUMENTS);
};
