import { SvgIcon, SvgIconProps } from '@mui/material';

function FirearmIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M27.1311 27.6295C26.8964 27.8642 25.4843 26.9223 25.2197 27.1225C25.1107 26.6355 24.9017 26.1473 24.6315 25.6737C23.3561 23.4276 20.7141 21.4894 20.7141 21.4894C22.8023 21.0846 24.8639 21.5294 26.6129 22.8226C26.9488 23.0695 27.2723 23.3486 27.5826 23.6587L27.7071 23.6765C30.4414 24.3748 27.9073 26.8533 27.1311 27.6295Z'
        fill='#313842'
      />
      <path
        d='M27.7069 23.6771L27.5824 23.6594C27.2722 23.3491 26.9486 23.07 26.6128 22.8232C26.248 23.7828 25.6821 24.6446 24.9693 25.3573C24.8604 25.4663 24.747 25.572 24.6313 25.6742C24.9016 26.1479 25.1105 26.636 25.2195 27.123C25.4842 26.9229 26.8964 27.8647 27.131 27.6301C27.9071 26.854 30.4412 24.3755 27.7069 23.6771Z'
        fill='#313842'
      />
      <path
        d='M27.5303 38.1979L27.5102 38.2579C26.6763 37.9821 25.2341 37.5975 24.3767 37.901C20.9175 39.2053 21.5313 46.0115 22.0139 49.1505C22.1417 49.9855 21.4979 50.7395 20.6528 50.7395H11.8652C10.8167 50.7395 9.97714 49.8755 10.0005 48.828C10.1929 40.0348 11.4649 35.3491 14.8029 32.0622C15.877 31.0059 18.2377 29.9262 18.8782 28.3428L18.9827 28.3517C24.0476 28.3516 30.3301 30.9357 27.5303 38.1979Z'
        fill='#313842'
      />
      <path
        d='M30.8826 35.0295C30.8826 36.8499 31.9873 38.4097 33.5637 39.0759C33.5637 39.0759 31.9841 35.6434 33.3 33.8527C34.6158 32.0622 30.8826 35.0295 30.8826 35.0295Z'
        fill='#313842'
      />
      <path
        d='M57.4055 22.0185V19.2598H56.1824C56.1824 19.2598 55.5152 21.0944 53.7361 21.0944V22.8735L57.4055 22.0185Z'
        fill='#313842'
      />
      <path
        d='M55.5677 29.3456V32.9883H44.0791V26.9883L55.5677 29.3456Z'
        fill='#868686'
      />
      <path d='M59.9994 22.0186H44.0786V29.304H59.9994V22.0186Z' fill='#868686' />
      <path d='M59.9994 23.6182H44.0786V25.2859H59.9994V23.6182Z' fill='#DCDDDE' />
      <path
        d='M27.8649 21.0244C27.8649 21.8351 27.7315 22.6145 27.4846 23.3428C27.1254 24.4058 26.5239 25.3587 25.7478 26.1349C25.5131 26.3695 25.2619 26.5886 24.9972 26.7887C23.7841 27.7071 22.2741 28.252 20.6362 28.252C20.6362 28.252 19.7511 28.302 18.9593 28.1041C18.9371 28.1842 18.9093 28.2643 18.8782 28.3421L18.9827 28.351C23.8174 29.0271 27.5301 33.1746 27.5301 38.1973L27.5101 38.2573C27.5507 38.2708 27.5888 38.2838 27.6264 38.2967C27.6264 38.3736 27.6283 38.4502 27.6334 38.5252C27.7493 40.4586 29.3365 41.9454 31.2646 41.9453C31.2928 41.9453 31.3212 41.945 31.3496 41.9444L34.1362 41.8833C36.6599 41.8281 38.7244 39.8086 38.8359 37.2856C38.836 37.2832 38.8361 37.2807 38.8362 37.2779C38.8439 37.0915 38.8361 36.91 38.8129 36.7384C38.7768 36.4448 38.7009 36.1641 38.5917 35.901C39.0065 35.2312 39.746 34.7837 40.5911 34.7837H44.1904V33.0991V29.4564V29.3041V22.0187V21.0246H27.8649V21.0244ZM37.725 37.2358C37.725 37.2379 37.7249 37.2399 37.7249 37.242C37.6363 39.1792 36.0502 40.7291 34.112 40.7715L31.325 40.8326C29.9588 40.8626 28.8251 39.8206 28.7432 38.4539C28.7368 38.3598 28.7362 38.2651 28.7415 38.1703C28.8351 36.3646 30.325 34.9502 32.1336 34.9502H32.1843L35.6181 35.0014C36.6819 35.0169 37.581 35.8232 37.7098 36.8769C37.7101 36.8794 37.7105 36.8819 37.7108 36.8844C37.7255 36.9937 37.7303 37.1119 37.725 37.2358Z'
        fill='#868686'
      />
      <path d='M55.5672 30.3242H44.0786V31.0677H55.5672V30.3242Z' fill='#DCDDDE' />
      <path d='M40.6286 23.1328H29.8293V32.3537H40.6286V23.1328Z' fill='#EFEFEF' />
      <path
        opacity='0.1'
        d='M40.6286 23.1328H29.8293V32.3537H40.6286V23.1328Z'
        fill='#313842'
      />
      <path
        d='M12.0018 48.828C12.1942 40.0348 13.4663 35.3491 16.8043 32.0622C17.7852 31.0975 19.8385 30.1131 20.6753 28.7436C20.1302 28.566 19.5648 28.4331 18.9827 28.3517L18.8782 28.3428C18.2377 29.9262 15.877 31.0059 14.8029 32.0622C11.4648 35.3491 10.1928 40.0349 10.0005 48.828C9.97714 49.8755 10.8167 50.7395 11.8652 50.7395H13.8666C12.8181 50.7395 11.9785 49.8755 12.0018 48.828Z'
        fill='#272B31'
      />
      <path
        d='M40.629 24.964V26.6318H34.6311C34.1708 26.6318 33.7971 26.2594 33.7971 25.7979C33.7971 25.3375 34.1708 24.9639 34.6311 24.9639H40.629V24.964Z'
        fill='#808080'
      />
      <path
        d='M40.629 28.8556V30.5234H34.6311C34.1708 30.5234 33.7971 30.151 33.7971 29.6895C33.7971 29.2291 34.1708 28.8555 34.6311 28.8555H40.629V28.8556Z'
        fill='#808080'
      />
      <path
        d='M16.2187 46.574C17.324 46.574 18.22 45.678 18.22 44.5727C18.22 43.4673 17.324 42.5713 16.2187 42.5713C15.1133 42.5713 14.2173 43.4673 14.2173 44.5727C14.2173 45.678 15.1133 46.574 16.2187 46.574Z'
        fill='#F5B53F'
      />
    </SvgIcon>
  );
}

FirearmIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default FirearmIcon;
