import { SvgIcon, SvgIconProps } from '@mui/material';

function HeartbeatIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M56.3274 26.3605C56.478 19.9743 51.4064 14.5623 45.0186 14.4929C40.742 14.4464 37.0009 16.7485 34.9993 20.1879C32.9973 16.7485 29.2566 14.446 24.98 14.4929C18.5922 14.5623 13.5206 19.9743 13.6712 26.3605C14.0085 40.6309 31.0762 53.0432 34.4305 55.3469C34.7735 55.5827 35.2251 55.5827 35.5681 55.3469C38.9223 53.0432 55.9901 40.6309 56.3274 26.3605Z'
        fill='#EE6338'
      />
      <path
        opacity='0.05'
        d='M56.3326 25.9337C56.3326 40.3811 38.9596 53.017 35.568 55.3462C35.2247 55.582 34.7726 55.5831 34.4289 55.3474C33.5935 54.7736 31.907 53.5728 29.8406 51.8725C33.2467 49.5318 50.5961 36.9032 50.5961 22.4646C50.5961 19.5318 49.494 16.8562 47.6797 14.834C52.651 16.0787 56.3326 20.5744 56.3326 25.9337Z'
        fill='#313842'
      />
      <path
        d='M34.8582 46.8802C34.2379 46.8802 33.705 46.4389 33.5898 45.8285L30.4381 29.0869L27.0846 37.0566C26.8893 37.5208 26.4422 37.8287 25.939 37.8458C25.4351 37.863 24.9685 37.5857 24.7423 37.1359L23.8459 35.3541H11.2905C10.5779 35.3541 10 34.7761 10 34.0636C10 33.3506 10.5779 32.773 11.2905 32.773H24.6412C25.1265 32.773 25.5705 33.045 25.7906 33.4768L29.7232 24.132C29.9441 23.6067 30.4835 23.2885 31.0492 23.3492C31.6157 23.4094 32.0754 23.8336 32.181 24.3936L34.7796 38.1987L35.855 31.7392C35.9584 31.1167 36.4966 30.6604 37.1276 30.6604H41.3394C41.7758 30.6604 42.1828 30.8809 42.4209 31.2464L44.3984 34.2818L45.2514 33.2442C45.4967 32.9458 45.8625 32.773 46.2485 32.773H58.7097C59.4223 32.773 59.9998 33.351 59.9998 34.0636C59.9998 34.7761 59.4223 35.3541 58.7097 35.3541H46.8585L45.2754 37.2801C45.0145 37.5975 44.6174 37.7718 44.2073 37.7493C43.7964 37.7268 43.4215 37.5098 43.1972 37.1653L40.6405 33.2415H38.2212L36.1312 45.8014C36.0285 46.4186 35.4975 46.8734 34.8719 46.8798C34.8673 46.8798 34.8624 46.8802 34.8582 46.8802Z'
        fill='#F5B53F'
      />
    </SvgIcon>
  );
}

HeartbeatIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default HeartbeatIcon;
