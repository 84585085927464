import { SvgIcon, SvgIconProps } from '@mui/material';

function ReportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M48.8135 59.5081H18.5736C17.5936 59.5081 16.7991 58.7137 16.7991 57.7336V14.7745C16.7991 13.7944 17.5935 13 18.5736 13H48.8135C49.7935 13 50.588 13.7944 50.588 14.7745V57.7337C50.5879 58.7137 49.7935 59.5081 48.8135 59.5081Z'
        fill='#313842'
      />
      <path
        d='M51.8636 56.5082H21.6236C20.6437 56.5082 19.8491 55.7138 19.8491 54.7337V11.7745C19.8491 10.7945 20.6436 10 21.6236 10H51.8636C52.8436 10 53.6381 10.7944 53.6381 11.7745V54.7337C53.6381 55.7138 52.8437 56.5082 51.8636 56.5082Z'
        fill='#EFEFEF'
      />
      <path
        opacity='0.05'
        d='M51.8579 10H48.1494C49.1294 10 49.9239 10.7944 49.9239 11.7745V54.7337C49.9239 55.7136 49.1295 56.5082 48.1494 56.5082H51.8579C52.8379 56.5082 53.6324 55.7137 53.6324 54.7337V11.7744C53.6324 10.7944 52.838 10 51.8579 10Z'
        fill='#313842'
      />
      <path
        d='M49.2279 18.058H39.4728C39.0853 18.058 38.771 17.7437 38.771 17.3562C38.771 16.9686 39.0853 16.6543 39.4728 16.6543H49.2279C49.6155 16.6543 49.9298 16.9686 49.9298 17.3562C49.9297 17.7438 49.6155 18.058 49.2279 18.058Z'
        fill='#313842'
      />
      <path
        d='M49.2279 21.3412H39.4728C39.0853 21.3412 38.771 21.027 38.771 20.6394C38.771 20.2518 39.0853 19.9375 39.4728 19.9375H49.2279C49.6155 19.9375 49.9298 20.2518 49.9298 20.6394C49.9297 21.027 49.6155 21.3412 49.2279 21.3412Z'
        fill='#313842'
      />
      <path
        d='M49.2279 24.6244H39.4728C39.0853 24.6244 38.771 24.3102 38.771 23.9226C38.771 23.535 39.0853 23.2207 39.4728 23.2207H49.2279C49.6155 23.2207 49.9298 23.535 49.9298 23.9226C49.9297 24.3103 49.6155 24.6244 49.2279 24.6244Z'
        fill='#313842'
      />
      <path
        d='M34.015 36.099H24.26C23.8724 36.099 23.5581 35.7848 23.5581 35.3972C23.5581 35.0096 23.8724 34.6953 24.26 34.6953H34.015C34.4026 34.6953 34.7169 35.0096 34.7169 35.3972C34.7169 35.7848 34.4026 36.099 34.015 36.099Z'
        fill='#313842'
      />
      <path
        d='M34.015 32.8158H24.26C23.8724 32.8158 23.5581 32.5016 23.5581 32.114C23.5581 31.7264 23.8724 31.4121 24.26 31.4121H34.015C34.4026 31.4121 34.7169 31.7264 34.7169 32.114C34.7169 32.5016 34.4026 32.8158 34.015 32.8158Z'
        fill='#313842'
      />
      <path
        d='M30.0776 13.8223V20.3415H36.5969C36.5969 16.7411 33.6781 13.8223 30.0776 13.8223Z'
        fill='#F5B53F'
      />
      <path
        d='M30.0773 13.8223C26.4769 13.8223 23.5581 16.741 23.5581 20.3415C23.5581 23.942 26.4769 26.8607 30.0773 26.8607C33.6778 26.8607 36.5966 23.942 36.5966 20.3415H30.0773V13.8223Z'
        fill='#313842'
      />
      <path
        d='M49.4626 37.5913H37.7428C37.4844 37.5913 37.2749 37.3818 37.2749 37.1234V30.3858C37.2749 30.1274 37.4844 29.918 37.7428 29.918H49.4626C49.721 29.918 49.9305 30.1274 49.9305 30.3858V37.1234C49.9305 37.3818 49.721 37.5913 49.4626 37.5913Z'
        fill='#EE6338'
      />
      <path
        d='M26.0781 51.489H24.0279C23.7695 51.489 23.5601 51.2795 23.5601 51.0211V40.7862C23.5601 40.5278 23.7695 40.3184 24.0279 40.3184H26.0781C26.3365 40.3184 26.546 40.5278 26.546 40.7862V51.0211C26.546 51.2795 26.3365 51.489 26.0781 51.489Z'
        fill='#4BABB3'
      />
      <path
        d='M31.0625 51.4891H29.0123C28.7539 51.4891 28.5444 51.2796 28.5444 51.0212V43.4718C28.5444 43.2134 28.7539 43.0039 29.0123 43.0039H31.0625C31.3209 43.0039 31.5304 43.2134 31.5304 43.4718V51.0212C31.5304 51.2796 31.3209 51.4891 31.0625 51.4891Z'
        fill='#4BABB3'
      />
      <path
        d='M36.0469 51.4891H33.9967C33.7383 51.4891 33.5288 51.2796 33.5288 51.0212V46.8175C33.5288 46.5591 33.7383 46.3496 33.9967 46.3496H36.0469C36.3053 46.3496 36.5147 46.5591 36.5147 46.8175V51.0212C36.5147 51.2796 36.3053 51.4891 36.0469 51.4891Z'
        fill='#4BABB3'
      />
      <path
        d='M49.2269 44.5131H40.1584C39.7708 44.5131 39.4565 44.1988 39.4565 43.8112C39.4565 43.4236 39.7708 43.1094 40.1584 43.1094H49.2269C49.6145 43.1094 49.9287 43.4236 49.9287 43.8112C49.9286 44.1988 49.6145 44.5131 49.2269 44.5131Z'
        fill='#313842'
      />
      <path
        d='M49.2248 47.9623H40.1583C39.7707 47.9623 39.4565 47.648 39.4565 47.2604C39.4565 46.8728 39.7707 46.5586 40.1583 46.5586H49.2248C49.6124 46.5586 49.9265 46.8728 49.9265 47.2604C49.9264 47.6481 49.6124 47.9623 49.2248 47.9623Z'
        fill='#313842'
      />
      <path
        d='M49.2248 51.4096H40.1583C39.7707 51.4096 39.4565 51.0953 39.4565 50.7077C39.4565 50.3201 39.7707 50.0059 40.1583 50.0059H49.2248C49.6124 50.0059 49.9265 50.3201 49.9265 50.7077C49.9264 51.0953 49.6124 51.4096 49.2248 51.4096Z'
        fill='#313842'
      />
    </SvgIcon>
  );
}

ReportIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default ReportIcon;
