import { SvgIcon, SvgIconProps } from '@mui/material';

function AccountingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M37.543 14.6299H34.2227C33.953 14.6299 33.7344 14.8485 33.7344 15.1182V16.5437H37.543V14.6299Z'
        fill='#EFEFF0'
      />
      <path
        d='M42.6353 45.0195V49.7463H51.8948C52.1645 49.7463 52.3831 49.5276 52.3831 49.258V45.0195H42.6353Z'
        fill='#EFEFF0'
      />
      <path
        opacity='0.05'
        d='M37.543 14.6299H34.2227C33.953 14.6299 33.7344 14.8485 33.7344 15.1182V16.5437H37.543V14.6299Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M42.6367 45.0205V49.7473H51.8963C52.1659 49.7473 52.3846 49.5286 52.3846 49.259V45.0205H42.6367Z'
        fill='#313842'
      />
      <path
        d='M55.6054 10H37.9336C37.664 10 37.4453 10.2187 37.4453 10.4883V16.543H41.7559C42.2405 16.543 42.6348 16.9373 42.6348 17.4219V45.1169H55.6054C55.875 45.1169 56.0937 44.8982 56.0937 44.6286V10.4883C56.0937 10.2187 55.875 10 55.6054 10Z'
        fill='#EFEFEF'
      />
      <path
        opacity='0.05'
        d='M41.3516 16.4453V10.4883C41.3516 10.2187 41.5702 10 41.8398 10H37.9336C37.664 10 37.4453 10.2187 37.4453 10.4883V16.4453H41.3516Z'
        fill='#313842'
      />
      <g opacity='0.2'>
        <path
          d='M49.5821 14.4834H43.9551C43.5505 14.4834 43.2227 14.1555 43.2227 13.751C43.2227 13.3465 43.5505 13.0186 43.9551 13.0186H49.5821C49.9867 13.0186 50.3146 13.3465 50.3146 13.751C50.3146 14.1555 49.9867 14.4834 49.5821 14.4834Z'
          fill='#313842'
        />
      </g>
      <path
        opacity='0.2'
        d='M53.2284 38.8613H42.6353V40.3262H53.2285C53.6331 40.3262 53.9609 39.9982 53.9609 39.5938C53.9609 39.1893 53.633 38.8613 53.2284 38.8613Z'
        fill='#313842'
      />
      <g opacity='0.2'>
        <path
          d='M46.1469 18.2285H42.6353V19.6934H46.1469C46.5515 19.6934 46.8793 19.3654 46.8793 18.9609C46.8793 18.5564 46.5514 18.2285 46.1469 18.2285Z'
          fill='#313842'
        />
        <path
          d='M46.1469 21.459H42.6353V22.9238H46.1469C46.5515 22.9238 46.8793 22.5959 46.8793 22.1914C46.8793 21.7869 46.5514 21.459 46.1469 21.459Z'
          fill='#313842'
        />
        <path
          d='M46.1469 24.6895H42.6353V26.1543H46.1469C46.5515 26.1543 46.8793 25.8264 46.8793 25.4219C46.8793 25.0174 46.5514 24.6895 46.1469 24.6895Z'
          fill='#313842'
        />
        <path
          d='M46.1469 27.9189H42.6353V29.3838H46.1469C46.5515 29.3838 46.8793 29.0559 46.8793 28.6514C46.8793 28.2469 46.5514 27.9189 46.1469 27.9189Z'
          fill='#313842'
        />
        <path
          d='M46.1469 31.1504H42.6353V32.6152H46.1469C46.5515 32.6152 46.8793 32.2873 46.8793 31.8828C46.8793 31.4783 46.5514 31.1504 46.1469 31.1504Z'
          fill='#313842'
        />
        <path
          d='M46.1469 34.3809H42.6353V35.8457H46.1469C46.5515 35.8457 46.8793 35.5178 46.8793 35.1133C46.8793 34.7088 46.5514 34.3809 46.1469 34.3809Z'
          fill='#313842'
        />
      </g>
      <path
        d='M42.3183 27H17.1646L16.7383 27.0977V55.2304H21.6007H41.7561C42.2954 55.2304 42.7326 54.7932 42.7326 54.2538V27.0977L42.3183 27Z'
        fill='#313842'
      />
      <g opacity='0.2'>
        <path
          d='M53.2276 19.6934H49.4263C49.0217 19.6934 48.6938 19.3654 48.6938 18.9609C48.6938 18.5564 49.0217 18.2285 49.4263 18.2285H53.2276C53.6322 18.2285 53.9601 18.5564 53.9601 18.9609C53.9601 19.3654 53.6322 19.6934 53.2276 19.6934Z'
          fill='#313842'
        />
        <path
          d='M53.2276 22.9238H49.4263C49.0217 22.9238 48.6938 22.5959 48.6938 22.1914C48.6938 21.7869 49.0217 21.459 49.4263 21.459H53.2276C53.6322 21.459 53.9601 21.7869 53.9601 22.1914C53.9601 22.5959 53.6322 22.9238 53.2276 22.9238Z'
          fill='#313842'
        />
        <path
          d='M53.2276 26.1543H49.4263C49.0217 26.1543 48.6938 25.8264 48.6938 25.4219C48.6938 25.0174 49.0217 24.6895 49.4263 24.6895H53.2276C53.6322 24.6895 53.9601 25.0174 53.9601 25.4219C53.9601 25.8264 53.6322 26.1543 53.2276 26.1543Z'
          fill='#313842'
        />
        <path
          d='M53.2276 29.3838H49.4263C49.0217 29.3838 48.6938 29.0559 48.6938 28.6514C48.6938 28.2469 49.0217 27.9189 49.4263 27.9189H53.2276C53.6322 27.9189 53.9601 28.2469 53.9601 28.6514C53.9601 29.0559 53.6322 29.3838 53.2276 29.3838Z'
          fill='#313842'
        />
        <path
          d='M53.2276 32.6152H49.4263C49.0217 32.6152 48.6938 32.2873 48.6938 31.8828C48.6938 31.4783 49.0217 31.1504 49.4263 31.1504H53.2276C53.6322 31.1504 53.9601 31.4783 53.9601 31.8828C53.9601 32.2873 53.6322 32.6152 53.2276 32.6152Z'
          fill='#313842'
        />
        <path
          d='M53.2276 35.8457H49.4263C49.0217 35.8457 48.6938 35.5178 48.6938 35.1133C48.6938 34.7088 49.0217 34.3809 49.4263 34.3809H53.2276C53.6322 34.3809 53.9601 34.7088 53.9601 35.1133C53.9601 35.5178 53.6322 35.8457 53.2276 35.8457Z'
          fill='#313842'
        />
      </g>
      <path
        d='M42.7326 27.7656H16.7383V17.4219C16.7383 16.8825 17.1755 16.4453 17.7148 16.4453H41.7561C42.2954 16.4453 42.7326 16.8825 42.7326 17.4219V27.7656Z'
        fill='#26A6FE'
      />
      <path
        d='M16.7383 27V55.2344C16.7383 55.2344 20.5 55.2344 20.8398 55.2344V27H16.7383Z'
        fill='#272B31'
      />
      <path
        d='M42.7326 27.7656H16.7383V17.4219C16.7383 16.8825 17.1755 16.4453 17.7148 16.4453H41.7561C42.2954 16.4453 42.7326 16.8825 42.7326 17.4219V27.7656Z'
        fill='#EE6338'
      />
      <path
        opacity='0.05'
        d='M19.668 25.3242C19.3983 25.3242 19.1797 25.1056 19.1797 24.8359V19.375C19.1797 19.1054 19.3983 18.8867 19.668 18.8867H20.8398V17.4219C20.8398 16.8825 21.2771 16.4453 21.8164 16.4453H17.7148C17.1755 16.4453 16.7383 16.8825 16.7383 17.4219V27.7656H20.8398V25.3242H19.668Z'
        fill='#313842'
      />
      <path
        d='M19.1797 24.8359V19.375C19.1797 19.1054 19.3983 18.8867 19.668 18.8867H39.8029C40.0726 18.8867 40.2912 19.1054 40.2912 19.375V24.8359C40.2912 25.1056 40.0726 25.3242 39.8029 25.3242H19.668C19.3983 25.3242 19.1797 25.1057 19.1797 24.8359Z'
        fill='#FEF3EF'
      />
      <path
        opacity='0.05'
        d='M23.2812 24.8359V19.375C23.2812 19.1054 23.4999 18.8867 23.7695 18.8867H19.668C19.3983 18.8867 19.1797 19.1054 19.1797 19.375V24.8359C19.1797 25.1056 19.3983 25.3242 19.668 25.3242H23.7695C23.4999 25.3242 23.2812 25.1057 23.2812 24.8359Z'
        fill='#313842'
      />
      <path
        d='M23.1997 33.6574H19.9431C19.6735 33.6574 19.4548 33.4388 19.4548 33.1691V30.4961C19.4548 30.2265 19.6735 30.0078 19.9431 30.0078H23.1997C23.4693 30.0078 23.6879 30.2265 23.6879 30.4961V33.1691C23.6879 33.4388 23.4693 33.6574 23.1997 33.6574Z'
        fill='#F1F8F9'
      />
      <path
        d='M28.6418 33.6574H25.3853C25.1156 33.6574 24.897 33.4388 24.897 33.1691V30.4961C24.897 30.2265 25.1156 30.0078 25.3853 30.0078H28.6418C28.9114 30.0078 29.1301 30.2265 29.1301 30.4961V33.1691C29.1301 33.4388 28.9114 33.6574 28.6418 33.6574Z'
        fill='#F1F8F9'
      />
      <path
        d='M34.0849 33.6574H30.8284C30.5587 33.6574 30.3401 33.4388 30.3401 33.1691V30.4961C30.3401 30.2265 30.5587 30.0078 30.8284 30.0078H34.0849C34.3545 30.0078 34.5732 30.2265 34.5732 30.4961V33.1691C34.5732 33.4388 34.3545 33.6574 34.0849 33.6574Z'
        fill='#F1F8F9'
      />
      <path
        d='M39.527 33.6574H36.2705C36.0009 33.6574 35.7822 33.4388 35.7822 33.1691V30.4961C35.7822 30.2265 36.0009 30.0078 36.2705 30.0078H39.527C39.7967 30.0078 40.0153 30.2265 40.0153 30.4961V33.1691C40.0153 33.4388 39.7967 33.6574 39.527 33.6574Z'
        fill='#F1F8F9'
      />
      <path
        d='M23.1997 38.5607H19.9431C19.6735 38.5607 19.4548 38.3421 19.4548 38.0724V35.3994C19.4548 35.1298 19.6735 34.9111 19.9431 34.9111H23.1997C23.4693 34.9111 23.6879 35.1298 23.6879 35.3994V38.0724C23.6879 38.3421 23.4693 38.5607 23.1997 38.5607Z'
        fill='#F1F8F9'
      />
      <path
        d='M28.6418 38.5607H25.3853C25.1156 38.5607 24.897 38.3421 24.897 38.0724V35.3994C24.897 35.1298 25.1156 34.9111 25.3853 34.9111H28.6418C28.9114 34.9111 29.1301 35.1298 29.1301 35.3994V38.0724C29.1301 38.3421 28.9114 38.5607 28.6418 38.5607Z'
        fill='#F1F8F9'
      />
      <path
        d='M34.0849 38.5607H30.8284C30.5587 38.5607 30.3401 38.3421 30.3401 38.0724V35.3994C30.3401 35.1298 30.5587 34.9111 30.8284 34.9111H34.0849C34.3545 34.9111 34.5732 35.1298 34.5732 35.3994V38.0724C34.5732 38.3421 34.3545 38.5607 34.0849 38.5607Z'
        fill='#F1F8F9'
      />
      <path
        d='M39.527 38.5607H36.2705C36.0009 38.5607 35.7822 38.3421 35.7822 38.0724V35.3994C35.7822 35.1298 36.0009 34.9111 36.2705 34.9111H39.527C39.7967 34.9111 40.0153 35.1298 40.0153 35.3994V38.0724C40.0153 38.3421 39.7967 38.5607 39.527 38.5607Z'
        fill='#B3DAFE'
      />
      <path
        d='M23.1997 43.466H19.9431C19.6735 43.466 19.4548 43.2474 19.4548 42.9777V40.3047C19.4548 40.0351 19.6735 39.8164 19.9431 39.8164H23.1997C23.4693 39.8164 23.6879 40.0351 23.6879 40.3047V42.9777C23.6879 43.2474 23.4693 43.466 23.1997 43.466Z'
        fill='#F1F8F9'
      />
      <path
        d='M28.6418 43.466H25.3853C25.1156 43.466 24.897 43.2474 24.897 42.9777V40.3047C24.897 40.0351 25.1156 39.8164 25.3853 39.8164H28.6418C28.9114 39.8164 29.1301 40.0351 29.1301 40.3047V42.9777C29.1301 43.2474 28.9114 43.466 28.6418 43.466Z'
        fill='#F1F8F9'
      />
      <path
        d='M34.0849 43.466H30.8284C30.5587 43.466 30.3401 43.2474 30.3401 42.9777V40.3047C30.3401 40.0351 30.5587 39.8164 30.8284 39.8164H34.0849C34.3545 39.8164 34.5732 40.0351 34.5732 40.3047V42.9777C34.5732 43.2474 34.3545 43.466 34.0849 43.466Z'
        fill='#F1F8F9'
      />
      <path
        d='M39.527 43.466H36.2705C36.0009 43.466 35.7822 43.2474 35.7822 42.9777V40.3047C35.7822 40.0351 36.0009 39.8164 36.2705 39.8164H39.527C39.7967 39.8164 40.0153 40.0351 40.0153 40.3047V42.9777C40.0153 43.2474 39.7967 43.466 39.527 43.466Z'
        fill='#EFEFEF'
      />
      <path
        d='M39.527 38.5607H36.2705C36.0009 38.5607 35.7822 38.3421 35.7822 38.0724V35.3994C35.7822 35.1298 36.0009 34.9111 36.2705 34.9111H39.527C39.7967 34.9111 40.0153 35.1298 40.0153 35.3994V38.0724C40.0153 38.3421 39.7967 38.5607 39.527 38.5607Z'
        fill='#F1F8F9'
      />
      <path
        opacity='0.05'
        d='M37.8167 33.1691V30.4961C37.8167 30.2265 38.0353 30.0078 38.305 30.0078H36.2705C36.0009 30.0078 35.7822 30.2265 35.7822 30.4961V33.1691C35.7822 33.4388 36.0009 33.6574 36.2705 33.6574H38.305C38.0353 33.6574 37.8167 33.4388 37.8167 33.1691Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M37.8167 38.0724V35.3994C37.8167 35.1298 38.0353 34.9111 38.305 34.9111H36.2705C36.0009 34.9111 35.7822 35.1298 35.7822 35.3994V38.0724C35.7822 38.3421 36.0009 38.5607 36.2705 38.5607H38.305C38.0353 38.5607 37.8167 38.3421 37.8167 38.0724Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M37.8167 42.9777V40.3047C37.8167 40.0351 38.0353 39.8164 38.305 39.8164H36.2705C36.0009 39.8164 35.7822 40.0351 35.7822 40.3047V42.9777C35.7822 43.2474 36.0009 43.466 36.2705 43.466H38.305C38.0353 43.466 37.8167 43.2474 37.8167 42.9777Z'
        fill='#313842'
      />
      <path
        d='M23.1997 48.3693H19.9431C19.6735 48.3693 19.4548 48.1507 19.4548 47.8811V45.208C19.4548 44.9384 19.6735 44.7197 19.9431 44.7197H23.1997C23.4693 44.7197 23.6879 44.9384 23.6879 45.208V47.8811C23.6879 48.1507 23.4693 48.3693 23.1997 48.3693Z'
        fill='#F1F8F9'
      />
      <path
        d='M28.6418 48.3693H25.3853C25.1156 48.3693 24.897 48.1507 24.897 47.8811V45.208C24.897 44.9384 25.1156 44.7197 25.3853 44.7197H28.6418C28.9114 44.7197 29.1301 44.9384 29.1301 45.208V47.8811C29.1301 48.1507 28.9114 48.3693 28.6418 48.3693Z'
        fill='#F1F8F9'
      />
      <path
        d='M34.0849 48.3693H30.8284C30.5587 48.3693 30.3401 48.1507 30.3401 47.8811V45.208C30.3401 44.9384 30.5587 44.7197 30.8284 44.7197H34.0849C34.3545 44.7197 34.5732 44.9384 34.5732 45.208V47.8811C34.5732 48.1507 34.3545 48.3693 34.0849 48.3693Z'
        fill='#F1F8F9'
      />
      <path
        d='M23.1997 53.2746H19.9431C19.6735 53.2746 19.4548 53.056 19.4548 52.7863V50.1133C19.4548 49.8437 19.6735 49.625 19.9431 49.625H23.1997C23.4693 49.625 23.6879 49.8437 23.6879 50.1133V52.7863C23.6879 53.056 23.4693 53.2746 23.1997 53.2746Z'
        fill='#F1F8F9'
      />
      <path
        d='M28.6418 53.2746H25.3853C25.1156 53.2746 24.897 53.056 24.897 52.7863V50.1133C24.897 49.8437 25.1156 49.625 25.3853 49.625H28.6418C28.9114 49.625 29.1301 49.8437 29.1301 50.1133V52.7863C29.1301 53.056 28.9114 53.2746 28.6418 53.2746Z'
        fill='#F1F8F9'
      />
      <path
        d='M34.0849 53.2746H30.8284C30.5587 53.2746 30.3401 53.056 30.3401 52.7863V50.1133C30.3401 49.8437 30.5587 49.625 30.8284 49.625H34.0849C34.3545 49.625 34.5732 49.8437 34.5732 50.1133V52.7863C34.5732 53.056 34.3545 53.2746 34.0849 53.2746Z'
        fill='#F1F8F9'
      />
      <path
        opacity='0.05'
        d='M32.3746 33.1691V30.4961C32.3746 30.2265 32.5932 30.0078 32.8628 30.0078H30.8284C30.5587 30.0078 30.3401 30.2265 30.3401 30.4961V33.1691C30.3401 33.4388 30.5587 33.6574 30.8284 33.6574H32.8628C32.5932 33.6574 32.3746 33.4388 32.3746 33.1691Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M32.3746 38.0724V35.3994C32.3746 35.1298 32.5932 34.9111 32.8628 34.9111H30.8284C30.5587 34.9111 30.3401 35.1298 30.3401 35.3994V38.0724C30.3401 38.3421 30.5587 38.5607 30.8284 38.5607H32.8628C32.5932 38.5607 32.3746 38.3421 32.3746 38.0724Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M32.3746 42.9777V40.3047C32.3746 40.0351 32.5932 39.8164 32.8628 39.8164H30.8284C30.5587 39.8164 30.3401 40.0351 30.3401 40.3047V42.9777C30.3401 43.2474 30.5587 43.466 30.8284 43.466H32.8628C32.5932 43.466 32.3746 43.2474 32.3746 42.9777Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M32.3746 47.8811V45.208C32.3746 44.9384 32.5932 44.7197 32.8628 44.7197H30.8284C30.5587 44.7197 30.3401 44.9384 30.3401 45.208V47.8811C30.3401 48.1507 30.5587 48.3693 30.8284 48.3693H32.8628C32.5932 48.3693 32.3746 48.1507 32.3746 47.8811Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M32.3746 52.7863V50.1133C32.3746 49.8437 32.5932 49.625 32.8628 49.625H30.8284C30.5587 49.625 30.3401 49.8437 30.3401 50.1133V52.7863C30.3401 53.056 30.5587 53.2746 30.8284 53.2746H32.8628C32.5932 53.2746 32.3746 53.056 32.3746 52.7863Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M26.9314 33.1691V30.4961C26.9314 30.2265 27.1501 30.0078 27.4197 30.0078H25.3853C25.1156 30.0078 24.897 30.2265 24.897 30.4961V33.1691C24.897 33.4388 25.1156 33.6574 25.3853 33.6574H27.4197C27.15 33.6574 26.9314 33.4388 26.9314 33.1691Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M26.9314 38.0724V35.3994C26.9314 35.1298 27.1501 34.9111 27.4197 34.9111H25.3853C25.1156 34.9111 24.897 35.1298 24.897 35.3994V38.0724C24.897 38.3421 25.1156 38.5607 25.3853 38.5607H27.4197C27.15 38.5607 26.9314 38.3421 26.9314 38.0724Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M26.9314 42.9777V40.3047C26.9314 40.0351 27.1501 39.8164 27.4197 39.8164H25.3853C25.1156 39.8164 24.897 40.0351 24.897 40.3047V42.9777C24.897 43.2474 25.1156 43.466 25.3853 43.466H27.4197C27.15 43.466 26.9314 43.2474 26.9314 42.9777Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M26.9314 47.8811V45.208C26.9314 44.9384 27.1501 44.7197 27.4197 44.7197H25.3853C25.1156 44.7197 24.897 44.9384 24.897 45.208V47.8811C24.897 48.1507 25.1156 48.3693 25.3853 48.3693H27.4197C27.15 48.3693 26.9314 48.1507 26.9314 47.8811Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M26.9314 52.7863V50.1133C26.9314 49.8437 27.1501 49.625 27.4197 49.625H25.3853C25.1156 49.625 24.897 49.8437 24.897 50.1133V52.7863C24.897 53.056 25.1156 53.2746 25.3853 53.2746H27.4197C27.15 53.2746 26.9314 53.056 26.9314 52.7863Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M21.4893 42.9777V40.3047C21.4893 40.0351 21.708 39.8164 21.9776 39.8164H19.9431C19.6735 39.8164 19.4548 40.0351 19.4548 40.3047V42.9777C19.4548 43.2474 19.6735 43.466 19.9431 43.466H21.9776C21.708 43.466 21.4893 43.2474 21.4893 42.9777Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M21.4893 47.882V45.209C21.4893 44.9394 21.708 44.7207 21.9776 44.7207H19.9431C19.6735 44.7207 19.4548 44.9394 19.4548 45.209V47.882C19.4548 48.1517 19.6735 48.3703 19.9431 48.3703H21.9776C21.708 48.3703 21.4893 48.1517 21.4893 47.882Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M21.4893 52.7863V50.1133C21.4893 49.8437 21.708 49.625 21.9776 49.625H19.9431C19.6735 49.625 19.4548 49.8437 19.4548 50.1133V52.7863C19.4548 53.056 19.6735 53.2746 19.9431 53.2746H21.9776C21.708 53.2746 21.4893 53.056 21.4893 52.7863Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M21.4893 33.1691V30.4961C21.4893 30.2265 21.708 30.0078 21.9776 30.0078H19.9431C19.6735 30.0078 19.4548 30.2265 19.4548 30.4961V33.1691C19.4548 33.4388 19.6735 33.6574 19.9431 33.6574H21.9776C21.708 33.6574 21.4893 33.4388 21.4893 33.1691Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M21.4893 38.0734V35.4004C21.4893 35.1308 21.708 34.9121 21.9776 34.9121H19.9431C19.6735 34.9121 19.4548 35.1308 19.4548 35.4004V38.0734C19.4548 38.3431 19.6735 38.5617 19.9431 38.5617H21.9776C21.708 38.5617 21.4893 38.3431 21.4893 38.0734Z'
        fill='#313842'
      />
      <path
        d='M39.527 53.2734H36.2705C36.0009 53.2734 35.7822 53.0548 35.7822 52.7852V45.208C35.7822 44.9384 36.0009 44.7197 36.2705 44.7197H39.527C39.7967 44.7197 40.0153 44.9384 40.0153 45.208V52.7852C40.0153 53.0548 39.7967 53.2734 39.527 53.2734Z'
        fill='#4BABB3'
      />
      <path
        opacity='0.05'
        d='M37.8315 52.7852V45.208C37.8315 44.9384 38.0502 44.7197 38.3198 44.7197H36.2705C36.0009 44.7197 35.7822 44.9384 35.7822 45.208V52.7852C35.7822 53.0548 36.0009 53.2734 36.2705 53.2734H38.3198C38.0502 53.2734 37.8315 53.0548 37.8315 52.7852Z'
        fill='#313842'
      />
    </SvgIcon>
  );
}

AccountingIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default AccountingIcon;
