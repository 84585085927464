import * as React from 'react';
// @mui
import Box from '@mui/material/Box';
// @ld
import { colors } from '@ld/utils';
// @custom
import { BodySmallText, SubText } from '../typography';
// @local
import FieldValueSkeleton from './skeleton';

const FieldValue = ({
  label,
  value,
  noWrap,
  textTransform,
  ValueComponent
}: {
  noWrap?: boolean;
  label: string | null;
  textTransform?: string;
  value?: string | number | null;
  ValueComponent?: React.ReactNode;
}) => {
  return (
    <Box my='15px'>
      <SubText color={colors.grey1}>{label}</SubText>
      {ValueComponent || (
        <BodySmallText noWrap={noWrap} textTransform={textTransform}>
          {value || '—'}
        </BodySmallText>
      )}
    </Box>
  );
};

export default FieldValue;

export { FieldValueSkeleton };
