import * as React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// @ld
import { IAdvisorRead } from '@ld/utils';
// @custom
import useAuth from 'hooks/use-auth';
import useProfile from 'hooks/use-profile';
import UserContext from 'context/user-context';
import { ProfileProvider } from 'context/profile-context';
import { SnackbarProvider } from 'context/snackbar-context';
import { ProfileRoleProvider } from 'context/profile-role-context';
import { DocumentUploadServiceProvider } from 'components/document-upload-service';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

type AppProviderProps = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => {
  const { user, isInitialising, setUser } = useAuth();
  const { profileId } = useProfile();
  const updateUser = React.useCallback(
    (data: Partial<IAdvisorRead>) => {
      if (user) {
        setUser({ ...user, ...data });
      }
    },
    [user]
  );

  if (isInitialising) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <UserContext.Provider
          value={{
            user,
            updateUser,
            setUser: (newUser: IAdvisorRead | null) => setUser(newUser)
          }}
        >
          <ProfileProvider>
            <DocumentUploadServiceProvider profileId={profileId}>
              <ProfileRoleProvider>{children}</ProfileRoleProvider>
            </DocumentUploadServiceProvider>
          </ProfileProvider>
        </UserContext.Provider>
      </SnackbarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppProvider;
