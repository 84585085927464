import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
// @ld
import { IAdvisorRead } from '@ld/utils';
// @custom
import { unauthorizedUrl } from 'constants/urls';

export default function useAuth() {
  const history = useHistory();
  const [user, setUser] = useState<IAdvisorRead | null>(null);
  const [isInitialising, setInitialising] = useState(true); // Helpful, to update the UI accordingly.

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();
    // Listen authenticated user
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // User is signed in.
          // You could also look for the user doc in your Firestore (if you have one):
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userRef);
          const userData = userDoc.exists() ? userDoc.data() : {};

          if (!userData?.advisor) {
            auth.signOut();
            history.replace(unauthorizedUrl);
          }

          setUser({
            uid: user.uid,
            name: userData.name,
            orgId: userData?.orgId,
            email: user.email || '',
            orgRole: userData?.orgRole,
            advisor: userData?.advisor,
            photoURL: userData.photoURL,
            isEmailVerified: user.emailVerified,
            // phoneNumber: userData?.phoneNumber,
            isTwoFactorEnabled: userData?.isTwoFactorEnabled,
            created: userData?.created,
            updated: userData?.updated
          });
        } else {
          setUser(null);
        }
      } catch (error) {
        // Most probably a connection error. Handle appropriately.
      } finally {
        setInitialising(false);
      }
    });

    // Unsubscribe auth listener on unmount
    return () => unsubscribe();
  }, []);

  return { user, isInitialising, setUser };
}
