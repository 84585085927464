import { IEventAtRead, IEventAtWrite } from './event';

export enum ETypesOfMarriages {
  IN_COMMUNITY = 'in_community',
  OUT_OF_COMMUNITY = 'out_of_community',
  CUSTOMARY_MARRIAGE = 'customary_marriage',
  HINDU_OR_MUSLIM_RELIGIOUS_MARRIAGE = 'hindu_or_muslim_religious_marriage'
}

export enum EMaritalStatus {
  SINGLE = 'single',
  MARRIED = 'married',
  WIDOWED = 'widowed',
  DIVORCED = 'divorced',
  PARTNERSHIP = 'partnership'
}

export interface IProfileDetailsWrite {
  idNumber?: string;
  taxNumber?: string;
  phoneNumber?: string;
  maritalStatus?: string;
  passportNumber?: string;
  typeOfMarriage?: string;
  created: IEventAtWrite;
  updated: IEventAtWrite;
}

export interface IProfileDetailsRead extends IProfileDetailsWrite {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
}
