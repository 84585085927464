import { EProfileType } from './index';
import { IEventAtRead, IEventAtWrite } from '../event';

// categories
export enum ECategoryType {
  DOCUMENT = 'document',
  ENTITY = 'entity'
}

// categories
export interface ICategoryWrite {
  name: string;
  order: number;
  iconURL: string;
  type: ECategoryType;
  isRequired: boolean;
  description: string;
  illustrationURL: string;
  shortDescription: string;
  profileType: EProfileType;
  created: IEventAtWrite;
  updated: IEventAtWrite;
  // entity category
  entity?: {
    type: string;
    displayName: string;
    pluralDisplayName: string;
  };
  // used to initialize the config of the documents in a category or a entity
  documentsConfig?: Record<string, boolean>; // dictionary of document types and enabled/disabled
}

export interface ICategoryRead extends ICategoryWrite {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
}

// documents
type TDocumentFormField = {
  type: string;
  name: string;
  label: string;
  isRequired: boolean;
  disablePast?: boolean;
};

export interface IDocumentWrite {
  type: string; // e.g. 'id_document', 'passport', 'drivers_license'
  name: string;
  order: number; // order in which this documents are displayed
  multiple: boolean; // allows multiple documents of this type
  isRequired: boolean;
  created: IEventAtWrite;
  updated: IEventAtWrite;
  formFields?: TDocumentFormField[]; // metadata that can or should be added when adding this document
}

export interface IDocumentRead extends IDocumentWrite {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
}

//profile categories
// only the stuff a user can write on a profile category
export interface IProfileCategoryWrite {
  enabled: boolean; // if false, the category isn't applicable to the profile
  documentsConfig?: Record<string, boolean>; // dictionary of document types and enabled/disabled
  access: Record<string, boolean>;
  updated: IEventAtWrite;
}

export interface IProfileCategoryRead extends IProfileCategoryWrite, ICategoryRead {
  id: string;
  updated: IEventAtRead;
  addedEntities?: string[]; // ids of added entities
  uploadedDocuments?: Record<string, string[]>; // dictionary of document types and ids
}
