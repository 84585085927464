import { Timestamp } from 'firebase/firestore';

import { TNullableString } from '../base';
import { IEventAtRead, IEventAtWrite } from '../event';

export enum EProfileType {
  TRUST = 'trust',
  PERSONAL = 'personal',
  BUSINESS = 'business'
}

export enum EProfileRole {
  NONE = 'none',
  OWNER = 'owner',
  READER = 'reader',
  EDITOR = 'editor',
  WRITER = 'writer'
}

export type TProfileRole = Record<string, Omit<EProfileRole, EProfileRole.NONE>>;

export enum EProfileStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  NOT_INVITED = 'not-invited',
  INVITE_PENDING = 'invite-pending'
}

export interface IProfileWrite {
  name: string;
  type: EProfileType;
  ownerUserId: string;
  countryCode: string;
  isVaultSetup: boolean;
  email: TNullableString;
  status: EProfileStatus;
  created: IEventAtWrite;
  updated: IEventAtWrite;
  access: Record<string, boolean>;
  photoURL: TNullableString;
  promoCode: TNullableString;
  estateReadinessScore: number;
  estateReadinessGapCount: number;
  billingUserId: TNullableString;
  paymentMethodId: TNullableString;
  suspendedReason: TNullableString;
  billingOrganisationId: TNullableString;
  estateReadinessGap?: Record<string, unknown> | null;
}
export interface IProfileRead extends IProfileWrite {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
}

export interface IProfileSummaryBaseDocument {
  url?: string;
  name: string;
  type: string;
  entityId?: string;
  categoryId: string;
  documentId?: string;
  categoryName?: string;
  categoryIconUrl?: string;
}

export interface IKYCDocs {
  proofOfResidence: {
    fileName?: string;
    name: 'Proof of residence';
  } & IProfileSummaryBaseDocument;
  proofOfIdentity: {
    name: 'ID Document';
    fileName?: string;
  } & IProfileSummaryBaseDocument;
}

export interface ILastWillAndTestament extends IProfileSummaryBaseDocument {
  name: 'Last will and testament';
  uploadedAt: Timestamp;
  lastUpdatedAt: Timestamp;
}
export interface IExpiringDocument extends IProfileSummaryBaseDocument {
  name: string;
  expiresAt: Timestamp;
}
export interface IProfileSummaryRead {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
  kyc: IKYCDocs;
  will: ILastWillAndTestament;
  expiringDocuments: Record<string, IExpiringDocument>;
}
