import * as React from 'react';
import { IoChevronDownOutline, IoCloudUploadOutline } from 'react-icons/io5';
// @mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiSnackbar, { SnackbarProps } from '@mui/material/Snackbar';
// @ld
import { colors, TUploadFileSuccess } from '@ld/utils';
// @local
import FileUploader from './file-uploader';
import { Container, Title } from './styles';
import { TDocumentUploadProcessing } from '../types';

interface UploadSnackbarProps extends SnackbarProps {
  uploadProcessingData: TDocumentUploadProcessing[];
  nextUploadProcessingData: TDocumentUploadProcessing[];
  onFileUploadComplete: (
    processingData: TDocumentUploadProcessing,
    uploadSuccessData: TUploadFileSuccess
  ) => void;
}

const UploadSnackbar = ({
  onClose,
  uploadProcessingData,
  nextUploadProcessingData,
  onFileUploadComplete,
  ...rest
}: UploadSnackbarProps) => {
  const [minimized, setMinimized] = React.useState<boolean>(false);
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      onClose={onClose}
      {...rest}
    >
      <Container $minimized={minimized}>
        {minimized ? (
          <IconButton aria-label='expand' onClick={() => setMinimized(false)}>
            <IoCloudUploadOutline color={colors.grey1} />
          </IconButton>
        ) : (
          <Box mb={1} display='flex' justifyContent='space-between' alignItems='center'>
            <Title>Encrypting &amp; uploading documents</Title>
            <IconButton
              size='small'
              aria-label='minimize'
              onClick={() => setMinimized(true)}
            >
              <IoChevronDownOutline color={colors.grey1} />
            </IconButton>
          </Box>
        )}
        {uploadProcessingData.map((processingData) => {
          const { data } = processingData;
          return (
            <div key={data.file.name}>
              <FileUploader
                enabled
                data={data}
                minimized={minimized}
                onSuccess={(uploadSuccessData) => {
                  onFileUploadComplete(processingData, uploadSuccessData);
                  // options?.onSuccess?.(d);
                }}
              />
            </div>
          );
        })}
        {nextUploadProcessingData.map((processingData) => {
          const { data } = processingData;
          return (
            <div key={data.file.name}>
              <FileUploader
                data={data}
                minimized={minimized}
                onSuccess={(uploadSuccessData) => {
                  onFileUploadComplete(processingData, uploadSuccessData);
                  // options?.onSuccess?.(d);
                }}
              />
            </div>
          );
        })}
      </Container>
    </MuiSnackbar>
  );
};

export default UploadSnackbar;
