import { SvgIcon, SvgIconProps } from '@mui/material';

function DependentsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M58.5121 50.8915L57.7992 45.5452C57.647 44.4038 56.8753 43.4404 55.7946 43.0428L51.573 41.4894C51.0661 41.3204 50.7242 40.8461 50.7242 40.3117V38.3099L53.3979 37.1958C54.7858 36.6176 55.6897 35.2616 55.6897 33.7582V33.1375C56.7181 33.1375 57.5518 32.3038 57.5518 31.2754C57.5518 30.247 56.7181 29.4134 55.6897 29.4134V28.172C55.6897 26.458 54.3002 23.8271 52.5863 23.8271H41.4139C39.6998 23.8271 38.3104 26.458 38.3104 28.172V29.4134C37.282 29.4134 36.4484 30.247 36.4484 31.2754C36.4484 32.3038 37.282 33.1375 38.3104 33.1375V33.7582C38.3104 35.2616 39.2144 36.6175 40.6022 37.1959L43.2759 38.3099V40.3117C43.2759 40.8461 42.934 41.3204 42.4272 41.4894L38.2055 43.0428C37.1248 43.4404 36.3532 44.4038 36.2009 45.5452L35.4881 50.8915C35.3393 52.0077 36.2076 52.9996 37.3338 52.9996H56.6664C57.7926 52.9996 58.661 52.0077 58.5121 50.8915Z'
        fill='#F6B47B'
      />
      <path
        d='M56.3097 32.5176H55.0684C55.0684 32.5176 54.4772 34.154 54.4477 36.2416C54.4311 37.4109 54.7341 38.7218 55.0684 39.9659H56.3097C56.644 38.7217 56.947 37.4109 56.9304 36.2416C56.9009 34.154 56.3097 32.5176 56.3097 32.5176Z'
        fill='#313842'
      />
      <path
        d='M38.9311 32.5176H37.6897C37.6897 32.5176 37.0985 34.154 37.069 36.2416C37.0525 37.4109 37.3555 38.7218 37.6897 39.9659H38.9311C39.2653 38.7217 39.5683 37.4109 39.5518 36.2416C39.5223 34.154 38.9311 32.5176 38.9311 32.5176Z'
        fill='#313842'
      />
      <path
        d='M38.015 28.5068H34.5869C34.1478 28.5068 33.8475 28.9503 34.0106 29.358L34.6408 30.9336C34.7828 31.2885 35.2087 31.432 35.5365 31.2353L38.015 29.7482V28.5068Z'
        fill='#E45D32'
      />
      <path
        d='M38.015 28.5066H34.5869C34.1478 28.5066 33.8475 28.0631 34.0106 27.6554L34.6408 26.0799C34.7828 25.725 35.2087 25.5814 35.5365 25.7781L38.015 27.2652V28.5066Z'
        fill='#EE6338'
      />
      <path
        d='M55.9854 28.5068H59.4134C59.8526 28.5068 60.1528 28.9503 59.9897 29.358L59.3595 30.9336C59.2175 31.2885 58.7916 31.432 58.4638 31.2353L55.9854 29.7482V28.5068Z'
        fill='#E45D32'
      />
      <path
        d='M55.9854 28.5066H59.4134C59.8526 28.5066 60.1528 28.0631 59.9897 27.6554L59.3595 26.0799C59.2175 25.725 58.7916 25.5814 58.4638 25.7781L55.9854 27.2652V28.5066Z'
        fill='#EE6338'
      />
      <path
        d='M50.7241 38.3105L43.2758 36.6553V40.3123C43.2758 40.7591 43.0286 41.1526 42.6538 41.3721C47.8594 42.2832 50.7241 38.3105 50.7241 38.3105Z'
        fill='#E2A071'
      />
      <path
        d='M38.3103 29.4134C37.2819 29.4134 36.4482 30.2471 36.4482 31.2754C36.4482 32.3038 37.2819 33.1375 38.3103 33.1375V33.7582C38.3103 35.2616 39.2143 36.6176 40.6021 37.1959L44.6126 38.867C45.369 39.1822 46.1804 39.3445 46.9999 39.3445C47.8194 39.3445 48.6307 39.1822 49.3871 38.867L53.3979 37.1959C54.7857 36.6176 55.6896 35.2616 55.6896 33.7582V33.1375C56.718 33.1375 57.5517 32.3038 57.5517 31.2754C57.5517 30.247 56.718 29.4134 55.6896 29.4134V27.3961C55.6896 25.425 54.0917 23.8271 52.1207 23.8271H41.8793C39.9082 23.8271 38.3103 25.425 38.3103 27.3961V29.4134Z'
        fill='#FDC88E'
      />
      <path
        d='M42.6554 31.8962C42.314 31.8962 42.0347 31.6169 42.0347 31.2756V30.6549C42.0347 30.3135 42.314 30.0342 42.6554 30.0342C42.9967 30.0342 43.276 30.3135 43.276 30.6549V31.2756C43.276 31.6169 42.9967 31.8962 42.6554 31.8962Z'
        fill='#313842'
      />
      <path
        d='M51.3448 31.8962C51.0034 31.8962 50.7241 31.6169 50.7241 31.2756V30.6549C50.7241 30.3135 51.0034 30.0342 51.3448 30.0342C51.6862 30.0342 51.9655 30.3135 51.9655 30.6549V31.2756C51.9655 31.6169 51.6862 31.8962 51.3448 31.8962Z'
        fill='#313842'
      />
      <path
        d='M47 36.2581C45.8965 36.2581 44.8643 35.9865 44.1681 35.5129C43.8848 35.3201 43.8111 34.934 44.0042 34.6507C44.1966 34.3676 44.5831 34.2939 44.8664 34.4867C45.3543 34.8185 46.152 35.0168 47 35.0168C47.848 35.0168 48.6457 34.8185 49.1336 34.4867C49.4176 34.2934 49.8031 34.3673 49.9959 34.6507C50.1889 34.934 50.1153 35.3201 49.8319 35.5129C49.1358 35.9865 48.1035 36.2581 47 36.2581Z'
        fill='#E2A071'
      />
      <path
        opacity='0.6'
        d='M38.3103 29.4142C37.2819 29.4142 36.4482 30.2479 36.4482 31.2763C36.4482 32.3047 37.2819 33.1384 38.3103 33.1384V33.7591C38.3103 35.2625 39.2143 36.6185 40.6021 37.1967L44.6125 38.8678C44.8088 38.9496 45.0091 39.02 45.212 39.0811C43.4396 37.7845 42.2598 36.6511 41.5685 35.91C41.0656 35.3708 40.7931 34.6592 40.7931 33.9218V30.4529C42.5698 29.8327 47.5569 27.7957 47.6207 24.4487L46.8448 23.6729C46.8448 23.6729 46.5086 28.1211 38.3103 29.4142Z'
        fill='#F6B47B'
      />
      <path
        d='M54.1254 42.3446L51.8919 45.918C51.5516 46.4624 50.9549 46.7932 50.3129 46.7932H43.6873C43.0453 46.7932 42.4486 46.4624 42.1083 45.918L39.8749 42.3446C39.6955 42.0576 39.3194 41.9672 39.0292 42.1413L36.9904 43.3646C36.6927 43.5432 36.5994 43.9313 36.7834 44.2258L39.5518 48.6553H54.4484L57.2168 44.2257C57.4009 43.9313 57.3075 43.5432 57.0098 43.3645L54.971 42.1412C54.6809 41.9672 54.3047 42.0576 54.1254 42.3446Z'
        fill='#E45C32'
      />
      <path d='M54.4483 48.6553H39.5518V53.0001H54.4483V48.6553Z' fill='#EE6338' />
      <path
        d='M50.1036 17C48.0863 17 47.0001 17.6207 47.0001 17.6207C47.0001 17.6207 45.9139 17 43.8967 17C41.8794 17 34.2242 20.8836 37.0691 29.8836C37.0691 29.8836 47.0001 28.1207 47.0001 23.8276C47.0001 28.1207 56.9311 29.8836 56.9311 29.8836C59.776 20.8836 52.1208 17 50.1036 17Z'
        fill='#313842'
      />
      <path
        d='M47.0001 17.621V17.6207C47.0001 17.6207 45.9139 17 43.8966 17C41.8794 17 34.2243 20.8836 37.0691 29.8836C37.0691 29.8836 38.2713 29.6697 39.8381 29.2109C38.0846 21.1406 45.0753 17.6272 47.0001 17.621Z'
        fill='#303236'
      />
      <path
        d='M38.9312 41.2072H37.6898C37.347 41.2072 37.0691 40.9293 37.0691 40.5865C37.0691 40.2437 37.347 39.9658 37.6898 39.9658H38.9312C39.2739 39.9658 39.5519 40.2437 39.5519 40.5865C39.5519 40.9294 39.2739 41.2072 38.9312 41.2072Z'
        fill='#EE6338'
      />
      <path
        d='M38.9311 41.207C38.9311 41.207 40.0173 43.0691 38.3104 45.5519C36.6035 43.0691 37.6898 41.207 37.6898 41.207'
        fill='#313842'
      />
      <path
        d='M55.0684 41.2072H56.3098C56.6526 41.2072 56.9305 40.9293 56.9305 40.5865C56.9305 40.2437 56.6526 39.9658 56.3098 39.9658H55.0684C54.7257 39.9658 54.4478 40.2437 54.4478 40.5865C54.4478 40.9294 54.7257 41.2072 55.0684 41.2072Z'
        fill='#EE6338'
      />
      <path
        d='M55.0684 41.207C55.0684 41.207 53.9822 43.0691 55.6891 45.5519C57.396 43.0691 56.3098 41.207 56.3098 41.207'
        fill='#313842'
      />
      <path
        d='M35.1521 50.9069L34.3665 44.6215C34.2479 43.6728 33.5951 42.8764 32.6881 42.5741L29.6586 41.5642C29.2426 41.4255 28.7862 41.5139 28.4654 41.7872L27.573 41.4897C27.0661 41.3207 26.7242 40.8464 26.7242 40.312V38.3102L29.3979 37.1961C30.7857 36.618 31.6896 35.262 31.6896 33.7586V33.1379C32.718 33.1379 33.5517 32.3042 33.5517 31.2758C33.5517 30.8119 33.3817 30.388 33.1011 30.062C33.6279 28.1442 35.1088 22.0779 33.5517 19.4828L34.1724 17C34.1724 17 27.6551 17.1552 24.2414 18.2414L25.4827 17C25.4827 17 16.7543 17 14.9316 20.1029L14.931 20.1034C14.931 20.1034 11.8276 20.0776 11.8276 23.8276C11.8276 25.2001 12.5931 28.6272 12.9218 30.0356C12.6275 30.3649 12.4483 30.7994 12.4483 31.2758C12.4483 32.3042 13.2819 33.1379 14.3103 33.1379V33.7586C14.3103 35.262 15.2143 36.6179 16.6021 37.1963L19.2759 38.3103V40.3121C19.2759 40.8465 18.9339 41.3208 18.4271 41.4898L17.5347 41.7873C17.2138 41.5139 16.7575 41.4256 16.3415 41.5643L13.312 42.5741C12.4049 42.8765 11.7521 43.6728 11.6335 44.6216L10.8479 50.907C10.709 52.0183 11.5755 52.9999 12.6955 52.9999H12.713H33.287H33.3044C34.4244 52.9999 35.2911 52.0183 35.1521 50.9069Z'
        fill='#F6B47B'
      />
      <path
        d='M26.7241 38.3105L19.2758 38.3106V40.3124C19.2758 40.7592 19.0286 41.1528 18.6538 41.3722C23.8594 42.2833 26.7241 38.3105 26.7241 38.3105Z'
        fill='#E2A071'
      />
      <path
        d='M13.069 30.6551C13.069 30.6551 11.8276 25.5753 11.8276 23.8276C11.8276 20.0776 14.9311 20.1034 14.9311 20.1034L14.9316 20.1029C16.7544 17 25.4828 17 25.4828 17L24.2414 18.2414C27.6552 17.1552 34.1724 17 34.1724 17L33.5517 19.4828C35.4138 22.5862 32.931 30.6551 32.931 30.6551H13.069Z'
        fill='#313842'
      />
      <path
        d='M25.4828 17C25.4828 17 16.7544 17 14.9316 20.1029L14.9311 20.1034C14.9311 20.1034 11.8276 20.0776 11.8276 23.8276C11.8276 25.5753 13.069 30.6551 13.069 30.6551C13.069 30.6551 25.9742 24.8879 34.1724 17C34.1724 17 27.6552 17.1552 24.2414 18.2414L25.4828 17Z'
        fill='#303236'
      />
      <path
        d='M31.6896 29.4142V28.1728C31.6896 26.4588 30.3001 25.0693 28.5861 25.0693H17.4137C15.6998 25.0693 14.3103 26.4588 14.3103 28.1728V29.4142C13.2819 29.4142 12.4482 30.2478 12.4482 31.2762C12.4482 32.3046 13.2819 33.1383 14.3103 33.1383V33.759C14.3103 35.2624 15.2143 36.6183 16.602 37.1967L20.6125 38.8677C21.369 39.1829 22.1804 39.3452 22.9999 39.3452C23.8193 39.3452 24.6306 39.1829 25.3871 38.8677L29.3978 37.1966C30.7856 36.6184 31.6896 35.2624 31.6896 33.759V33.1383C32.718 33.1383 33.5516 32.3046 33.5516 31.2762C33.5516 30.2478 32.718 29.4142 31.6896 29.4142Z'
        fill='#FDC88E'
      />
      <path
        d='M18.6551 31.8962C18.3137 31.8962 18.0344 31.6169 18.0344 31.2756V30.6549C18.0344 30.3135 18.3137 30.0342 18.6551 30.0342C18.9965 30.0342 19.2758 30.3135 19.2758 30.6549V31.2756C19.2758 31.6169 18.9965 31.8962 18.6551 31.8962Z'
        fill='#313842'
      />
      <path
        d='M27.3448 31.8962C27.0034 31.8962 26.7241 31.6169 26.7241 31.2756V30.6549C26.7241 30.3135 27.0034 30.0342 27.3448 30.0342C27.6862 30.0342 27.9655 30.3135 27.9655 30.6549V31.2756C27.9655 31.6169 27.6862 31.8962 27.3448 31.8962Z'
        fill='#313842'
      />
      <path
        d='M23 36.2581C21.8965 36.2581 20.8643 35.9865 20.1681 35.5129C19.8848 35.3201 19.8111 34.934 20.0042 34.6507C20.1966 34.3676 20.5831 34.2939 20.8664 34.4867C21.3543 34.8185 22.152 35.0168 23 35.0168C23.848 35.0168 24.6457 34.8185 25.1336 34.4867C25.4176 34.2934 25.8031 34.3673 25.9958 34.6507C26.1889 34.934 26.1153 35.3201 25.8319 35.5129C25.1358 35.9865 24.1035 36.2581 23 36.2581Z'
        fill='#E2A071'
      />
      <path
        d='M35.1521 50.9067L34.3665 44.6213C34.2479 43.6726 33.595 42.8762 32.6881 42.5739L29.6586 41.564C29.131 41.3881 28.5375 41.576 28.2394 42.0455C27.1391 43.7791 25.2053 44.9307 23 44.9307C20.7947 44.9307 18.8608 43.779 17.7606 42.0455C17.4626 41.576 16.8691 41.3881 16.3415 41.564L13.312 42.5739C12.4049 42.8762 11.7521 43.6726 11.6335 44.6213L10.8479 50.9067C10.709 52.018 11.5756 52.9997 12.6956 52.9997H33.3045C34.4244 52.9997 35.2911 52.018 35.1521 50.9067Z'
        fill='#4BABB3'
      />
      <g opacity='0.1'>
        <path
          d='M12.151 43.3926C11.8754 43.7415 11.6909 44.163 11.6335 44.6218L10.8479 50.9072C10.709 52.0186 11.5756 53.0002 12.6956 53.0002H16.1724V48.9605C16.1724 48.3634 15.9572 47.7863 15.5662 47.3349L12.151 43.3926Z'
          fill='#313842'
        />
      </g>
      <g opacity='0.1'>
        <path
          d='M33.8491 43.3926C34.1247 43.7415 34.3092 44.163 34.3665 44.6218L35.1521 50.9072C35.2911 52.0186 34.4245 53.0002 33.3044 53.0002H29.8276V48.9605C29.8276 48.3634 30.0429 47.7863 30.4338 47.3349L33.8491 43.3926Z'
          fill='#313842'
        />
      </g>
      <path
        opacity='0.6'
        d='M17.5686 35.9099C17.0657 35.3707 16.7931 34.6591 16.7931 33.9217V26.8878C18.7687 26.7397 24.9974 26.201 28.5862 25.0693H17.4137C17.2001 25.0693 16.9932 25.0942 16.7931 25.1377V25.1319C15.3766 25.4195 14.3103 26.6714 14.3103 28.1728V29.4142C13.2819 29.4142 12.4482 30.2478 12.4482 31.2762C12.4482 32.3046 13.2819 33.1383 14.3103 33.1383V33.759C14.3103 35.2624 15.2143 36.6184 16.602 37.1967L20.6125 38.8677C20.8088 38.9495 21.0091 39.0199 21.212 39.081C19.4396 37.7844 18.2598 36.6509 17.5686 35.9099Z'
        fill='#F6B47B'
      />
    </SvgIcon>
  );
}

DependentsIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default DependentsIcon;
