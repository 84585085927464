import { SvgIcon, SvgIconProps } from '@mui/material';

function TrustIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M19.493 19.1947V52.5209C19.493 55.1423 21.618 57.2674 24.2394 57.2674L50.0925 54.0358V19.1947V17.4779C50.0925 14.8565 47.9674 12.7314 45.346 12.7314H14.7466L19.493 19.1947Z'
        fill='#EFEFEF'
      />
      <path
        d='M14.7465 12.7314C12.1251 12.7314 10 14.8565 10 17.4779V19.1947H19.4929V17.4779C19.4929 14.8565 17.3678 12.7314 14.7465 12.7314Z'
        fill='#E5E6E6'
      />
      <path
        d='M51.8093 57.267C54.4307 57.267 56.5558 55.1419 56.5558 52.5205V50.8037H28.986V52.5205C28.986 55.1419 26.8609 57.267 24.2395 57.267H51.8093Z'
        fill='#E5E6E6'
      />
      <path
        d='M45.548 26.7659H25.4513C25.0346 26.7659 24.6968 26.4281 24.6968 26.0114C24.6968 25.5947 25.0346 25.2568 25.4513 25.2568H45.548C45.9647 25.2568 46.3025 25.5947 46.3025 26.0114C46.3025 26.4281 45.9647 26.7659 45.548 26.7659Z'
        fill='#313842'
      />
      <path
        d='M45.548 22.7659H25.4513C25.0346 22.7659 24.6968 22.4281 24.6968 22.0114C24.6968 21.5947 25.0346 21.2568 25.4513 21.2568H45.548C45.9647 21.2568 46.3025 21.5947 46.3025 22.0114C46.3025 22.4281 45.9647 22.7659 45.548 22.7659Z'
        fill='#313842'
      />
      <path
        d='M45.548 30.553H25.4513C25.0346 30.553 24.6968 30.2152 24.6968 29.7985C24.6968 29.3818 25.0346 29.0439 25.4513 29.0439H45.548C45.9647 29.0439 46.3025 29.3818 46.3025 29.7985C46.3025 30.2152 45.9647 30.553 45.548 30.553Z'
        fill='#313842'
      />
      <path
        d='M45.548 34.3401H25.4513C25.0346 34.3401 24.6968 34.0023 24.6968 33.5856C24.6968 33.1689 25.0346 32.8311 25.4513 32.8311H45.548C45.9647 32.8311 46.3025 33.1689 46.3025 33.5856C46.3025 34.0023 45.9647 34.3401 45.548 34.3401Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M37.4309 34.2367L34.2189 37.4486C32.7174 38.9503 32.7174 41.3936 34.219 42.8952C34.9465 43.6227 35.9137 44.0233 36.9423 44.0233C37.9711 44.0233 38.9383 43.6227 39.6657 42.8952L42.8776 39.6833C45.3663 39.1822 47.8101 38.2368 50.0924 36.9106V19.1946V17.4778C50.0924 17.4211 50.0901 17.3648 50.0881 17.3086C48.0139 18.4647 46.0658 19.9323 44.3341 21.664C40.7848 25.2133 38.3545 29.6494 37.4309 34.2367Z'
        fill='#313842'
      />
      <path
        d='M45.5478 46.8792H39.5895C39.1728 46.8792 38.835 46.5413 38.835 46.1246C38.835 45.7079 39.1728 45.3701 39.5895 45.3701H45.5478C45.9645 45.3701 46.3024 45.7079 46.3024 46.1246C46.3024 46.5413 45.9645 46.8792 45.5478 46.8792Z'
        fill='#EE6338'
      />
      <path
        d='M59.971 19.2787C60.194 18.0222 59.092 16.9201 57.8355 17.1432C53.7265 17.8723 49.5559 19.9991 46.1124 23.4425C41.9942 27.5608 39.7592 32.7191 39.5459 37.5683C44.395 37.355 49.5533 35.1201 53.6716 31.0018C57.1151 27.5583 59.2419 23.3876 59.971 19.2787Z'
        fill='#F5B53F'
      />
      <path
        d='M35.9973 41.1169C35.4755 40.595 35.4755 39.7489 35.9973 39.227L52.2978 22.9265C52.8197 22.4047 53.6658 22.4047 54.1877 22.9265C54.7095 23.4484 54.7095 24.2945 54.1877 24.8164L37.8871 41.1169C37.3652 41.6387 36.5192 41.6387 35.9973 41.1169Z'
        fill='#313842'
      />
    </SvgIcon>
  );
}

TrustIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default TrustIcon;
