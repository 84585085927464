import { SvgIcon, SvgIconProps } from '@mui/material';

function AgreementIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M50.8279 14.3278V56.3881C50.8279 58.0679 49.4661 59.4297 47.7863 59.4297H18.0416C16.3618 59.4297 15 58.0679 15 56.3881V14.3268C15 12.6469 16.3618 11.2852 18.0416 11.2852H47.7852C49.4656 11.2851 50.8279 12.6473 50.8279 14.3278Z'
        fill='#EFEFEF'
      />
      <path
        opacity='0.05'
        d='M50.8275 14.3273V56.3878C50.8275 58.0673 49.465 59.4289 47.7854 59.4289H44.6426C46.3222 59.4289 47.6838 58.0673 47.6838 56.3878V14.3273C47.6838 12.6467 46.3212 11.2852 44.6406 11.2852H47.7844C49.465 11.2852 50.8275 12.6467 50.8275 14.3273Z'
        fill='#313842'
      />
      <path
        d='M41.2082 16.0423V18.6578C41.2082 18.9718 40.9536 19.2264 40.6396 19.2264H25.0296C24.7155 19.2264 24.4609 18.9718 24.4609 18.6578V16.0423C24.4609 15.7282 24.7155 15.4736 25.0296 15.4736H40.6395C40.9536 15.4736 41.2082 15.7282 41.2082 16.0423Z'
        fill='#313842'
      />
      <path
        d='M51.2128 34.469C51.1123 34.4722 51.0097 34.4498 50.9147 34.399C50.6261 34.2446 50.5174 33.8854 50.6719 33.5968L55.8138 23.9923C55.8882 23.8534 55.8358 23.6805 55.6969 23.6061L54.5376 22.9855C54.2515 22.8323 54.12 22.4782 54.2645 22.1876C54.4139 21.887 54.781 21.7714 55.0749 21.9288L57.0199 22.9701C57.0925 23.0089 57.1594 23.0593 57.2107 23.1236C57.3653 23.3171 57.3825 23.5733 57.273 23.7777L51.7168 34.1563C51.6132 34.3498 51.4175 34.4624 51.2128 34.469Z'
        fill='#4BABB3'
      />
      <path
        d='M41.2083 16.0422V18.6577C41.2083 18.9716 40.9537 19.2262 40.6398 19.2262H37.4336C37.7476 19.2262 38.0022 18.9716 38.0022 18.6577V16.0422C38.0022 15.7282 37.7476 15.4736 37.4336 15.4736H40.6398C40.9537 15.4736 41.2083 15.7282 41.2083 16.0422Z'
        fill='#272B31'
      />
      <path
        d='M48.5188 34.177L40.386 49.3684L37.8815 48.7758L35.4981 46.7517L43.6301 31.5598L46.3853 31.8623L48.5188 34.177Z'
        fill='#313842'
      />
      <path
        d='M48.5176 34.1773L40.3853 49.3683L38.4435 48.3296L38.1677 48.8441L46.9378 32.4632L48.5176 34.1773Z'
        fill='#272B31'
      />
      <path
        d='M55.317 18.9718C55.3269 19.2822 55.258 19.5998 55.1007 19.8943L54.2489 21.4854L52.3771 20.9589L51.0144 19.7541L51.867 18.1622C52.2407 17.4648 53.0001 17.1076 53.7394 17.2127C53.9467 17.2409 54.1535 17.307 54.349 17.4114C54.9482 17.7322 55.2966 18.3376 55.317 18.9718Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M55.101 19.8934L54.2487 21.4845L53.0266 21.0886L53.9863 19.2968C54.1442 19.0033 54.2129 18.6855 54.2026 18.3746C54.1893 17.9484 54.0274 17.5352 53.7398 17.212C53.9467 17.2405 54.1537 17.3063 54.3487 17.4107C54.9482 17.7316 55.2969 18.3374 55.3173 18.9711C55.3269 19.282 55.2582 19.5989 55.101 19.8934Z'
        fill='#313842'
      />
      <path
        d='M55.0762 21.9281L48.5189 34.1765L43.6303 31.5594L49.9041 19.8419C50.061 19.5489 50.4257 19.4386 50.7187 19.5954L55.0762 21.9281Z'
        fill='#313842'
      />
      <path
        d='M55.0775 21.9289L48.5202 34.1774L46.5792 33.1379L53.1358 20.8893L55.0775 21.9289Z'
        fill='#272B31'
      />
      <path
        d='M40.6654 51.6954V51.6964L36.7578 50.0088L40.6654 51.6954Z'
        fill='#5986CB'
      />
      <path
        d='M40.3922 49.3693L36.2782 52.6312C35.7663 53.0373 35.0166 52.6361 35.0709 51.9853L35.1576 50.9413L35.5039 46.752L38.4513 48.3303L40.3922 49.3693Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M40.3875 49.3677L36.2736 52.6296C35.762 53.0362 35.0123 52.6346 35.0662 51.9841L35.1533 50.94L38.4465 48.329L40.3875 49.3677Z'
        fill='#313842'
      />
      <path
        d='M44.1777 24.6111H20.4992C20.0984 24.6111 19.7734 24.2732 19.7734 23.8563C19.7734 23.4394 20.0984 23.1016 20.4992 23.1016H44.1777C44.5786 23.1016 44.9034 23.4394 44.9034 23.8563C44.9034 24.2732 44.5786 24.6111 44.1777 24.6111Z'
        fill='#313842'
      />
      <path
        d='M41.2643 29.3591H20.4125C20.0596 29.3591 19.7734 29.0213 19.7734 28.6044C19.7734 28.1874 20.0596 27.8496 20.4125 27.8496H41.2643C41.6174 27.8496 41.9034 28.1874 41.9034 28.6044C41.9034 29.0213 41.6174 29.3591 41.2643 29.3591Z'
        fill='#313842'
      />
      <path
        d='M38.0564 34.1081H20.4905C20.0945 34.1081 19.7734 33.7703 19.7734 33.3534C19.7734 32.9365 20.0945 32.5986 20.4905 32.5986H38.0564C38.4524 32.5986 38.7734 32.9365 38.7734 33.3534C38.7734 33.7703 38.4524 34.1081 38.0564 34.1081Z'
        fill='#313842'
      />
      <path
        d='M27.9251 38.8572H20.5282C20.1114 38.8572 19.7734 38.5193 19.7734 38.1024C19.7734 37.6855 20.1114 37.3477 20.5282 37.3477H27.9251C28.3419 37.3477 28.6798 37.6855 28.6798 38.1024C28.6798 38.5193 28.342 38.8572 27.9251 38.8572Z'
        fill='#313842'
      />
      <path
        d='M28.3561 53.5679C28.2665 53.5679 28.1753 53.5653 28.0823 53.5599C26.8876 53.4902 26.1865 52.8684 25.5679 52.3199C24.9876 51.8052 24.5292 51.3988 23.6647 51.3532C22.0015 51.2669 21.1781 52.7165 21.1438 52.7781C20.9428 53.1411 20.4854 53.276 20.1209 53.0772C19.7566 52.8784 19.6199 52.4251 19.8164 52.0596C19.868 51.9634 21.1095 49.7081 23.7441 49.8459C25.1357 49.9193 25.9307 50.6241 26.5694 51.1906C27.1084 51.6685 27.4979 52.0138 28.1702 52.053C29.9577 52.1589 30.8095 50.6582 30.8178 50.643C31.0199 50.2783 31.4792 50.1466 31.8438 50.3486C32.2084 50.5506 32.3402 51.0099 32.1382 51.3745C32.0871 51.467 30.8929 53.5678 28.3561 53.5679Z'
        fill='#4BABB3'
      />
    </SvgIcon>
  );
}

AgreementIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default AgreementIcon;
