import * as R from 'rambda';

import type {
  IProfileDocumentRead,
  IProfileDocumentWrite
} from '../types/profiles/documents';
import type { TGenericObject } from '../types/base';

export const prepareDocumentData = ({
  type,
  fileSize,
  formValues
}: {
  type: string;
  fileSize: number;
  formValues: TGenericObject;
}) => {
  const formData = R.pick(
    [
      'expiresAt',
      'displayName',
      'policyNumber',
      'lastUpdatedAt',
      'institutionName',
      'physicalStorageLocation'
    ],
    formValues
  );
  const documentData: Omit<
    IProfileDocumentWrite,
    'url' | 'fileName' | 'created' | 'updated'
  > = {
    type,
    fileSize,
    ...formData
  };

  return documentData;
};

export const toEditableDocument = (values?: IProfileDocumentRead) =>
  R.pick(
    [
      'displayName',
      'policyNumber',
      'institutionName',
      'physicalStorageLocation',
      'expiresAt',
      'lastUpdatedAt'
    ],
    values
  );
