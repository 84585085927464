import { SvgIcon, SvgIconProps } from '@mui/material';

function IdentificationCardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M60 26.3787V48.7925C60 50.2208 58.8421 51.3787 57.4138 51.3787H12.5862C11.1579 51.3787 10 50.2208 10 48.7925V22.0684L29.0431 22.0684C31.2201 22.0657 33.3287 22.8286 35 24.2235C35.8268 24.9174 36.7694 25.4602 37.7845 25.827C38.8022 26.1925 39.8755 26.3792 40.9569 26.3787H60Z'
        fill='#ECF0F1'
      />
      <path
        d='M60 21.2068V27.2413L40.9569 27.2413C39.8755 27.2418 38.8022 27.0551 37.7845 26.6896C36.7694 26.3228 35.8268 25.78 35 25.0861C33.3287 23.6911 31.2201 22.9283 29.0431 22.9309H10V21.2068C10 19.7785 11.1579 18.6206 12.5862 18.6206H57.4138C58.8421 18.6206 60 19.7785 60 21.2068Z'
        fill='#313842'
      />
      <path
        d='M26.1214 37.3452C29.4004 38.6465 31.5531 41.8174 31.5524 45.3452C31.5454 46.2945 30.7776 47.0623 29.8283 47.0693H16.0352C15.0841 47.0665 14.3139 46.2962 14.311 45.3452C14.3072 41.8164 16.461 38.644 19.7421 37.3452H26.1214Z'
        fill='#4BABB3'
      />
      <path
        d='M53.965 35.1372H36.7236C36.2475 35.1372 35.8616 34.7513 35.8616 34.2752C35.8616 33.799 36.2475 33.4131 36.7236 33.4131H53.965C54.4411 33.4131 54.8271 33.799 54.8271 34.2752C54.8271 34.7513 54.4411 35.1372 53.965 35.1372Z'
        fill='#313842'
      />
      <path
        d='M48.0899 39.4478H36.9732C36.3593 39.4478 35.8616 39.0618 35.8616 38.5857C35.8616 38.1096 36.3593 37.7236 36.9732 37.7236H48.0899C48.7039 37.7236 49.2016 38.1096 49.2016 38.5857C49.2016 39.0618 48.7039 39.4478 48.0899 39.4478Z'
        fill='#313842'
      />
      <path
        d='M53.965 43.7583H36.7236C36.2475 43.7583 35.8616 43.3724 35.8616 42.8962C35.8616 42.4201 36.2475 42.0342 36.7236 42.0342H53.965C54.4411 42.0342 54.8271 42.4201 54.8271 42.8962C54.8271 43.3724 54.4411 43.7583 53.965 43.7583Z'
        fill='#313842'
      />
      <path
        d='M44.4832 23.7925H41.0349C40.5588 23.7925 40.1729 23.4065 40.1729 22.9304C40.1729 22.4543 40.5588 22.0684 41.0349 22.0684H44.4832C44.9593 22.0684 45.3453 22.4543 45.3453 22.9304C45.3453 23.4065 44.9593 23.7925 44.4832 23.7925Z'
        fill='#EFEFEF'
      />
      <path
        d='M55.6893 23.7925H47.9307C47.4546 23.7925 47.0686 23.4065 47.0686 22.9304C47.0686 22.4543 47.4546 22.0684 47.9307 22.0684H55.6893C56.1654 22.0684 56.5514 22.4543 56.5514 22.9304C56.5514 23.4065 56.1654 23.7925 55.6893 23.7925Z'
        fill='#EFEFEF'
      />
      <path
        d='M28.1034 33.2755C28.1034 36.1321 25.7876 38.4479 22.931 38.4479C20.0743 38.4479 17.7585 36.1321 17.7585 33.2755C17.7585 30.4188 20.0743 28.1031 22.931 28.1031C24.304 28.0989 25.6221 28.6425 26.593 29.6134C27.5639 30.5844 28.1075 31.9024 28.1034 33.2755Z'
        fill='#F5B53F'
      />
    </SvgIcon>
  );
}

IdentificationCardIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default IdentificationCardIcon;
