import { SvgIcon, SvgIconProps } from '@mui/material';

function WalletIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M40.6122 15.7242L35.7071 13.1725L31.1122 10.7846L30.3536 10.388C28.6609 9.51103 26.5778 10.1707 25.6984 11.8622L17.2588 28.1036L37.2993 29.5691L40.6122 15.7242Z'
        fill='#4BABB3'
      />
      <path
        d='M22.1123 28.1031L31.1123 10.7842L35.7071 13.1721L27.9511 28.1031H22.1123Z'
        fill='#313842'
      />
      <path
        d='M44.4827 60.0003H51.3792C53.2836 60.0003 54.8275 58.4564 54.8275 56.552V33.1123H46.3275L44.4827 60.0003Z'
        fill='#272B31'
      />
      <path
        d='M23.4404 28.103L30.6473 22.9134L40.6128 15.7237L42.8801 14.0944C44.4284 12.9844 46.5827 13.3351 47.6991 14.8789L48.1991 15.5772L51.2249 19.7754L56.768 27.4737C57.8786 29.0189 57.5275 31.1717 55.9835 32.2841L54.8284 33.1116L48.7939 37.4651L23.4404 28.103Z'
        fill='#F5B53F'
      />
      <path
        d='M30.8105 28.104L48.1985 15.5781L51.2244 19.7764L39.664 28.104H30.8105Z'
        fill='#313842'
      />
      <path d='M48.7939 40.1729H54.8284V47.0694H48.7939V40.1729Z' fill='#868686' />
      <path
        opacity='0.05'
        d='M48.7935 40.1729H54.8279V47.0694H48.7935V40.1729Z'
        fill='#313842'
      />
      <path
        d='M45.345 28.1045H16.0347C14.1303 28.1045 12.5864 29.6483 12.5864 31.5528V56.5528C12.5864 58.4572 14.1303 60.001 16.0347 60.001H45.345C47.2495 60.001 48.7933 58.4572 48.7933 56.5528V31.5528C48.7933 29.6483 47.2495 28.1045 45.345 28.1045Z'
        fill='#313842'
      />
      <path
        d='M18.6206 32.4146H16.8965C16.4204 32.4146 16.0344 32.0286 16.0344 31.5525C16.0344 31.0764 16.4204 30.6904 16.8965 30.6904H18.6206C19.0967 30.6904 19.4827 31.0764 19.4827 31.5525C19.4827 32.0286 19.0967 32.4146 18.6206 32.4146Z'
        fill='#868686'
      />
      <path
        d='M23.7937 32.4146H22.0696C21.5935 32.4146 21.2075 32.0286 21.2075 31.5525C21.2075 31.0764 21.5935 30.6904 22.0696 30.6904H23.7937C24.2698 30.6904 24.6558 31.0764 24.6558 31.5525C24.6558 32.0286 24.2698 32.4146 23.7937 32.4146Z'
        fill='#868686'
      />
      <path
        d='M28.9649 32.4146H27.2407C26.7646 32.4146 26.3787 32.0286 26.3787 31.5525C26.3787 31.0764 26.7646 30.6904 27.2407 30.6904H28.9649C29.441 30.6904 29.8269 31.0764 29.8269 31.5525C29.8269 32.0286 29.441 32.4146 28.9649 32.4146Z'
        fill='#868686'
      />
      <path
        d='M34.138 32.4146H32.4138C31.9377 32.4146 31.5518 32.0286 31.5518 31.5525C31.5518 31.0764 31.9377 30.6904 32.4138 30.6904H34.138C34.6141 30.6904 35 31.0764 35 31.5525C35 32.0286 34.6141 32.4146 34.138 32.4146Z'
        fill='#868686'
      />
      <path
        d='M39.3111 32.4146H37.5869C37.1108 32.4146 36.7249 32.0286 36.7249 31.5525C36.7249 31.0764 37.1108 30.6904 37.5869 30.6904H39.3111C39.7872 30.6904 40.1731 31.0764 40.1731 31.5525C40.1731 32.0286 39.7872 32.4146 39.3111 32.4146Z'
        fill='#868686'
      />
      <path
        d='M44.4822 32.4146H42.7581C42.282 32.4146 41.896 32.0286 41.896 31.5525C41.896 31.0764 42.282 30.6904 42.7581 30.6904H44.4822C44.9583 30.6904 45.3443 31.0764 45.3443 31.5525C45.3443 32.0286 44.9583 32.4146 44.4822 32.4146Z'
        fill='#868686'
      />
      <path
        d='M18.6206 57.4146H16.8965C16.4204 57.4146 16.0344 57.0286 16.0344 56.5525C16.0344 56.0764 16.4204 55.6904 16.8965 55.6904H18.6206C19.0967 55.6904 19.4827 56.0764 19.4827 56.5525C19.4827 57.0286 19.0967 57.4146 18.6206 57.4146Z'
        fill='#868686'
      />
      <path
        d='M23.7937 57.4146H22.0696C21.5935 57.4146 21.2075 57.0286 21.2075 56.5525C21.2075 56.0764 21.5935 55.6904 22.0696 55.6904H23.7937C24.2698 55.6904 24.6558 56.0764 24.6558 56.5525C24.6558 57.0286 24.2698 57.4146 23.7937 57.4146Z'
        fill='#868686'
      />
      <path
        d='M28.9649 57.4146H27.2407C26.7646 57.4146 26.3787 57.0286 26.3787 56.5525C26.3787 56.0764 26.7646 55.6904 27.2407 55.6904H28.9649C29.441 55.6904 29.8269 56.0764 29.8269 56.5525C29.8269 57.0286 29.441 57.4146 28.9649 57.4146Z'
        fill='#868686'
      />
      <path
        d='M34.138 57.4146H32.4138C31.9377 57.4146 31.5518 57.0286 31.5518 56.5525C31.5518 56.0764 31.9377 55.6904 32.4138 55.6904H34.138C34.6141 55.6904 35 56.0764 35 56.5525C35 57.0286 34.6141 57.4146 34.138 57.4146Z'
        fill='#868686'
      />
      <path
        d='M39.3111 57.4146H37.5869C37.1108 57.4146 36.7249 57.0286 36.7249 56.5525C36.7249 56.0764 37.1108 55.6904 37.5869 55.6904H39.3111C39.7872 55.6904 40.1731 56.0764 40.1731 56.5525C40.1731 57.0286 39.7872 57.4146 39.3111 57.4146Z'
        fill='#868686'
      />
      <path
        d='M44.4822 57.4146H42.7581C42.282 57.4146 41.896 57.0286 41.896 56.5525C41.896 56.0764 42.282 55.6904 42.7581 55.6904H44.4822C44.9583 55.6904 45.3443 56.0764 45.3443 56.5525C45.3443 57.0286 44.9583 57.4146 44.4822 57.4146Z'
        fill='#868686'
      />
      <path
        d='M40.1731 40.1729H48.7938V47.0694H40.1731C38.2687 47.0694 36.7249 45.5256 36.7249 43.6211C36.7249 41.7167 38.2687 40.1729 40.1731 40.1729Z'
        fill='#868686'
      />
      <path
        d='M40.1733 44.4819C40.6494 44.4819 41.0354 44.096 41.0354 43.6199C41.0354 43.1438 40.6494 42.7578 40.1733 42.7578C39.6972 42.7578 39.3113 43.1438 39.3113 43.6199C39.3113 44.096 39.6972 44.4819 40.1733 44.4819Z'
        fill='#D1D4D1'
      />
    </SvgIcon>
  );
}

WalletIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default WalletIcon;
