import { SvgIcon, SvgIconProps } from '@mui/material';

function TimeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M6.61602 7.50012C4.7968 9.53193 3.77828 12.1558 3.75021 14.8829C3.68107 21.1143 8.76875 26.2383 15.0002 26.2501C21.2228 26.2618 26.2501 21.221 26.2501 15.0001C26.2501 8.87472 21.3546 3.89017 15.2638 3.75013C15.2296 3.74903 15.1954 3.75484 15.1634 3.76722C15.1314 3.77961 15.1023 3.7983 15.0777 3.8222C15.0531 3.84609 15.0335 3.8747 15.0202 3.90631C15.0069 3.93792 15.0001 3.97189 15.0002 4.00619V8.90637' />
      <path d='M13.6746 16.331L9.04572 9.70997C8.98168 9.61824 8.95197 9.50689 8.9618 9.39544C8.97164 9.284 9.02038 9.17957 9.09949 9.10046C9.17859 9.02135 9.28302 8.97261 9.39447 8.96278C9.50591 8.95295 9.61726 8.98266 9.709 9.0467L16.3301 13.6756C16.7362 13.9681 17.0108 14.4087 17.0946 14.9021C17.1784 15.3955 17.0645 15.9021 16.7777 16.3122C16.4908 16.7224 16.054 17.0031 15.5618 17.0937C15.0696 17.1842 14.5615 17.0774 14.1475 16.7963C13.9644 16.6694 13.8044 16.512 13.6746 16.331Z' />
    </SvgIcon>
  );
}
TimeIcon.defaultProps = {
  viewBox: '0 0 30 30'
};
export default TimeIcon;
