import { SvgIcon, SvgIconProps } from '@mui/material';

function PawprintIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M28.1028 29.9972C31.4356 29.9972 34.1373 26.1376 34.1373 21.3765C34.1373 16.6155 31.4356 12.7559 28.1028 12.7559C24.7701 12.7559 22.0684 16.6155 22.0684 21.3765C22.0684 26.1376 24.7701 29.9972 28.1028 29.9972Z'
        fill='#F5B53F'
      />
      <path
        d='M41.8965 29.9972C45.2293 29.9972 47.931 26.1376 47.931 21.3765C47.931 16.6155 45.2293 12.7559 41.8965 12.7559C38.5638 12.7559 35.8621 16.6155 35.8621 21.3765C35.8621 26.1376 38.5638 29.9972 41.8965 29.9972Z'
        fill='#F5B53F'
      />
      <path
        d='M20.3448 31.7213C20.3448 36.4825 19.7534 40.342 16.8966 40.342C14.0397 40.342 10 36.4825 10 31.7213C10 26.9601 12.3155 23.1006 15.1724 23.1006C18.0293 23.1006 20.3448 26.9601 20.3448 31.7213Z'
        fill='#F5B53F'
      />
      <path
        d='M60.0003 31.7213C60.0003 36.4825 55.9607 40.342 53.1038 40.342C50.2469 40.342 49.6555 36.4825 49.6555 31.7213C49.6555 26.9601 51.971 23.1006 54.8279 23.1006C57.6848 23.1006 60.0003 26.9601 60.0003 31.7213Z'
        fill='#F5B53F'
      />
      <path
        d='M48.7925 46.5001C48.7925 51.6648 46.1201 56.0044 40.1718 57.2346C39.0701 57.4622 36.1899 52.4113 34.9994 52.4113C33.8089 52.4113 30.9287 57.4622 29.827 57.2346C23.8787 56.0044 21.2063 51.6648 21.2063 46.5001C21.2063 40.3786 27.3813 31.7217 34.9994 31.7217C42.6175 31.7217 48.7925 40.3786 48.7925 46.5001Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.1574 56.6659C43.7178 54.9584 45.7917 51.0489 45.7917 46.4992C45.7917 40.7849 40.4111 32.8614 33.4986 31.8324C33.9913 31.759 34.4918 31.7207 34.9986 31.7207C42.6167 31.7207 48.7917 40.3776 48.7917 46.4992C48.7917 51.6638 46.1193 56.0035 40.171 57.2336C39.9148 57.2866 39.5624 57.054 39.1574 56.6659ZM33.4986 53.5689C32.9045 52.9007 32.3696 52.4104 31.9986 52.4104C31.415 52.4104 30.4253 53.6242 29.4381 54.835C28.8799 55.5196 28.3225 56.2032 27.8398 56.6659C28.4562 56.8966 29.118 57.0872 29.8262 57.2336C30.3878 57.3497 31.4116 56.094 32.4381 54.835C32.7979 54.3938 33.158 53.9521 33.4986 53.5689Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.6052 29.7287C27.0849 29.904 27.5876 29.9972 28.1056 29.9972C31.4383 29.9972 34.14 26.1376 34.14 21.3765C34.14 16.6155 31.4383 12.7559 28.1056 12.7559C27.5876 12.7559 27.0849 12.8491 26.6052 13.0244C29.2121 13.9773 31.1394 17.3554 31.1394 21.3765C31.1394 25.3977 29.2121 28.7758 26.6052 29.7287Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.3982 29.7287C40.8779 29.904 41.3806 29.9972 41.8985 29.9972C45.2313 29.9972 47.933 26.1376 47.933 21.3765C47.933 16.6155 45.2313 12.7559 41.8985 12.7559C41.3806 12.7559 40.8779 12.8491 40.3982 13.0244C43.0051 13.9773 44.9323 17.3554 44.9323 21.3765C44.9323 25.3977 43.0051 28.7758 40.3982 29.7287Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2449 39.975C16.9496 38.9037 17.3448 35.6143 17.3448 31.7222C17.3448 27.8302 15.7976 24.5408 13.6721 23.4694C14.1468 23.2302 14.6503 23.1016 15.1718 23.1016C18.0287 23.1016 20.3443 26.961 20.3443 31.7222C20.3443 36.4835 19.7529 40.3429 16.896 40.3429C16.3744 40.3429 15.8135 40.2143 15.2449 39.975Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51.7554 39.9751C54.3015 38.9039 57.001 35.6144 57.001 31.7222C57.001 27.8301 55.4537 24.5406 53.3281 23.4694C53.8028 23.2302 54.3062 23.1016 54.8277 23.1016C57.6846 23.1016 60.0001 26.961 60.0001 31.7222C60.0001 36.4835 55.9604 40.3429 53.1036 40.3429C52.5821 40.3429 52.1361 40.2143 51.7554 39.9751Z'
        fill='#313842'
      />
      <path
        d='M24.6542 47.2396H24.637C24.161 47.2303 23.7827 46.8367 23.7922 46.3607C23.7922 46.3605 23.7922 46.3604 23.7922 46.3603C23.8879 41.6008 28.9465 34.3086 34.9991 34.3086C35.4751 34.3086 35.8611 34.6946 35.8611 35.1707C35.8611 35.6467 35.4751 36.0327 34.9991 36.0327C29.9689 36.0327 25.5973 42.3974 25.5163 46.3948C25.5069 46.8642 25.1237 47.2397 24.6542 47.2396Z'
        fill='#EE6338'
      />
    </SvgIcon>
  );
}

PawprintIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default PawprintIcon;
