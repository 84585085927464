import * as React from 'react';
// @mui
import Box from '@mui/material/Box';
// @custom
import { IllustrationWrapper } from './styles';
import { Heading3, BodySmallText } from '../typography';

type IllustrationBoxProps = {
  title?: string;
  message?: string;
  illustration: React.ComponentType;
  children?: React.ReactNode;
};

const IllustrationBox = ({
  title,
  message,
  illustration: Illustration,
  children
}: IllustrationBoxProps) => (
  <Box
    display='flex'
    flexDirection='column'
    justifyContent='center'
    alignItems='center'
    maxWidth={500}
    mx='auto'
  >
    <IllustrationWrapper>
      <Illustration />
    </IllustrationWrapper>
    {title && <Heading3 align='center'>{title}</Heading3>}
    {message && (
      <Box mt={2} mb={3}>
        <BodySmallText align='center'>{message}</BodySmallText>
      </Box>
    )}
    {children}
  </Box>
);

export default IllustrationBox;
