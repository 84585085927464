import { useHistory } from 'react-router-dom';
import { IoLogOutOutline } from 'react-icons/io5';
// @mui
import Box from '@mui/material/Box';
// @ld
import { IOrganisationRead } from '@ld/utils';
import { config, UnstyledLink } from '@ld/core';
// @custom
import {
  removeTwoFactorVerified,
  removeSigninEmailNotificationSentFlag
} from 'services/session-storage';
import useUser from 'hooks/use-user';
import { useSignOut } from 'api/auth';
import { getProfilesUrl } from 'utils/urls';
import useGetItem from 'api/utils/use-get-item';
import { ORGANISATIONS } from 'api/organisations';
// @local
import {
  ASide,
  Footer,
  Button,
  Content,
  PoweredByLink,
  OrganisationLogo
} from './styles';
import Logo from '../logo';
import MenuItems, { HelpAndSupport } from '../menu-items';

const Sidebar = () => {
  const history = useHistory();
  const { user, setUser } = useUser();

  const { data: org } = useGetItem<IOrganisationRead>({
    id: user?.orgId,
    collection: ORGANISATIONS,
    enabled: Boolean(user?.orgId)
  });

  const { handleSignOut } = useSignOut({
    onSuccess: () => {
      setUser(null);
      removeTwoFactorVerified();
      removeSigninEmailNotificationSentFlag();
      history.replace('/');
    }
  });

  return (
    <ASide>
      <Box p='30px'>
        <UnstyledLink to={getProfilesUrl()}>
          {org ? <OrganisationLogo src={org.logoURL || ''} alt={org.name} /> : <Logo />}
        </UnstyledLink>
        {org && (
          <PoweredByLink href={config.websiteUrl} target='_blank'>
            Powered by
            <Logo height={15} />
          </PoweredByLink>
        )}
      </Box>
      <Content>
        {MenuItems.map(({ allow, component: Component }, index) => {
          const allowed = allow.some((role) => user?.orgRole === role);
          if (allowed) {
            return <Component key={`sidebar-menu-item-${index}`} />;
          }
          return null;
        })}
      </Content>
      <Footer>
        <HelpAndSupport />
        <Button
          fullWidth
          onClick={() => handleSignOut()}
          startIcon={<IoLogOutOutline size={30} />}
          aria-label='logout'
        >
          Logout
        </Button>
      </Footer>
    </ASide>
  );
};

export default Sidebar;
