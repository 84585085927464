import { SvgIcon, SvgIconProps } from '@mui/material';

function AccessIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M42.0313 54.5313H27.9688L29.9777 47.5H40.0224L42.0313 54.5313Z'
        fill='#272B31'
      />
      <path
        d='M56.0001 48.4766H14C11.7909 48.4766 10 46.6866 10 44.4785V18.4903C10 16.2822 11.7909 14.4922 14 14.4922H56.0001C58.2092 14.4922 60.0001 16.2822 60.0001 18.4903V44.4784C60.0001 46.6866 58.2092 48.4766 56.0001 48.4766Z'
        fill='#313842'
      />
      <path
        d='M55.9466 45.4493H13.9988C13.4472 45.4493 13 45.0026 13 44.4517V18.5171C13 17.9662 13.4472 17.5195 13.9988 17.5195H55.9466C56.4982 17.5195 56.9454 17.9662 56.9454 18.5171V44.4517C56.9454 45.0027 56.4982 45.4493 55.9466 45.4493Z'
        fill='#EFEFEF'
      />
      <path
        d='M43.9648 55.5078H26.0352C25.4851 55.5078 25.0391 55.0487 25.0391 54.4824C25.0391 53.9162 25.4851 53.457 26.0352 53.457H43.9648C44.5149 53.457 44.961 53.9162 44.961 54.4824C44.961 55.0487 44.5149 55.5078 43.9648 55.5078Z'
        fill='#313842'
      />
      <path
        d='M42.3541 37.082L42.8845 36.5514C43.3728 36.0631 43.3728 35.2716 42.8845 34.7833C42.3963 34.296 41.6052 34.2955 41.1169 34.7837L40.5865 35.3143L39.7684 34.4961L40.2986 33.9658C40.7869 33.4776 40.7869 32.6865 40.2986 32.1982C39.8104 31.7098 39.0194 31.7099 38.531 32.1982L38.0007 32.7284L34.7432 29.4709C35.063 28.8668 35.2507 28.1989 35.2507 27.4961C35.2507 26.3613 34.8083 25.294 34.0056 24.4917C33.2038 23.6885 32.1365 23.2461 31.0007 23.2461C29.865 23.2461 28.7976 23.6885 27.9958 24.4907C27.1931 25.2934 26.7507 26.3609 26.7507 27.4961C26.7507 28.6314 27.1931 29.6987 27.9958 30.5004C28.7976 31.3037 29.865 31.7461 31.0007 31.7461C31.7039 31.7461 32.3719 31.5582 32.9757 31.2387L39.7018 37.9648C39.7021 37.9651 39.7022 37.9658 39.7022 37.9658C39.7031 37.9667 39.7037 37.9668 39.7041 37.9672L41.1168 39.3798C41.3609 39.6239 41.6802 39.746 42.0005 39.746C42.3207 39.746 42.6401 39.6239 42.8842 39.3798C43.3724 38.8915 43.3724 38.1005 42.8842 37.6121L42.3541 37.082ZM29.7635 28.7329C29.4323 28.4028 29.2507 27.9633 29.2507 27.496C29.2507 27.0287 29.4323 26.5893 29.7635 26.2583C30.0945 25.9281 30.533 25.746 31.0007 25.746C31.4685 25.746 31.907 25.9281 32.238 26.2583C32.238 26.2587 32.238 26.2587 32.238 26.2587C32.569 26.5894 32.7507 27.0287 32.7507 27.496C32.7507 27.9627 32.5693 28.4018 32.2388 28.7328C32.2388 28.7328 32.2381 28.7331 32.2379 28.7335C32.2376 28.7337 32.2375 28.7342 32.2375 28.7342C31.5753 29.394 30.4252 29.3947 29.7635 28.7329Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 18.5195C13 17.9672 13.4477 17.5195 14 17.5195H16V42.45L56.95 42.45V44.45C56.95 44.896 56.658 45.2739 56.2547 45.4027L56.95 45.4502H13V44.45V44.4495V42.4502V42.45V18.5195Z'
        fill='#313842'
      />
    </SvgIcon>
  );
}

AccessIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default AccessIcon;
