import { SvgIcon, SvgIconProps } from '@mui/material';

function CarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M58.6321 35.4226C57.8911 33.6842 56.2619 32.3828 54.2498 32.1524L46.6189 31.2804L42.5482 24.6643C41.5075 22.9723 39.663 21.9414 37.6769 21.9414H24.5332C21.1083 21.9414 18.1042 24.2294 17.1955 27.5321L17.0927 27.9053C16.805 28.9535 16.2522 29.8871 15.5112 30.6325C14.7701 31.3789 13.8387 31.936 12.7937 32.2313C11.6643 32.5494 10.8833 33.5803 10.8833 34.753V42.0964L15.0384 41.1247C15.1261 40.9213 15.2256 40.7233 15.3359 40.5308C16.3733 38.7339 18.3043 37.6175 20.377 37.6175C22.4497 37.6175 24.3796 38.7339 25.416 40.5308C25.5263 40.7233 25.6269 40.9213 25.7135 41.1247H43.3552C43.4406 40.9235 43.5391 40.7277 43.6483 40.5373C44.6847 38.7361 46.6178 37.6175 48.6938 37.6175C50.7665 37.6175 52.6964 38.7339 53.7327 40.5308C53.843 40.7233 53.9437 40.9213 54.0302 41.1247H58.6624L58.6916 41.1279L59.0291 38.1638C59.0432 38.0427 59.0529 37.9226 59.0583 37.8025C59.0984 36.963 58.9437 36.1539 58.6321 35.4226Z'
        fill='#EE6338'
      />
      <path
        d='M60.0006 42.43C60.0006 43.1498 59.4171 43.7333 58.6973 43.7333H54.5005C54.5059 43.6338 54.5092 43.5332 54.5092 43.4326C54.5092 42.6353 54.3458 41.8477 54.031 41.124L58.698 41.1266C59.4175 41.1269 60.0006 41.7103 60.0006 42.43Z'
        fill='#313842'
      />
      <path
        d='M53.3197 43.4323C53.3197 43.533 53.3164 43.6336 53.3099 43.7331C53.1552 46.1465 51.1485 48.057 48.695 48.057C46.2405 48.057 44.2338 46.1465 44.0791 43.7331C44.0726 43.6336 44.0693 43.533 44.0693 43.4323C44.0693 42.594 44.2922 41.8075 44.6816 41.1292C45.48 39.7413 46.9782 38.8066 48.695 38.8066C50.4086 38.8066 51.9047 39.7391 52.703 41.1238C53.0957 41.8032 53.3197 42.5918 53.3197 43.4323ZM50.5914 43.4323C50.5914 42.3863 49.7411 41.5349 48.695 41.5349C47.6479 41.5349 46.7976 42.3863 46.7976 43.4323C46.7976 44.4784 47.6479 45.3298 48.695 45.3298C49.7411 45.3298 50.5914 44.4784 50.5914 43.4323Z'
        fill='#313842'
      />
      <path
        d='M48.6926 41.5352C49.7387 41.5352 50.589 42.3865 50.589 43.4326C50.589 44.4787 49.7387 45.3301 48.6926 45.3301C47.6454 45.3301 46.7952 44.4787 46.7952 43.4326C46.7952 42.3865 47.6454 41.5352 48.6926 41.5352Z'
        fill='#EFEFEF'
      />
      <path
        d='M42.8773 43.4326C42.8773 43.5332 42.8805 43.6348 42.8859 43.7333H26.1821C26.1875 43.6327 26.1908 43.5321 26.1908 43.4326C26.1908 42.6353 26.0274 41.8477 25.7126 41.124H43.3543C43.0406 41.8499 42.8773 42.6364 42.8773 43.4326Z'
        fill='#313842'
      />
      <path
        d='M22.5453 24.9707V31.2807H18.9246C18.9246 31.2807 19.3465 29.5683 19.6137 28.599L19.7164 28.2258C19.7705 28.0278 19.8365 27.8363 19.9133 27.6503C20.4131 26.4365 21.3694 25.4824 22.5453 24.9707Z'
        fill='#FEF3EF'
      />
      <path
        d='M31.6378 24.5547V31.2801H24.167V24.5687C24.2882 24.559 24.4104 24.5547 24.5326 24.5547H31.6378Z'
        fill='#FEF3EF'
      />
      <path
        d='M43.5496 31.2801H33.2607V24.5547H37.6766C38.7465 24.5547 39.7612 25.1215 40.3215 26.0335L43.5496 31.2801Z'
        fill='#FEF3EF'
      />
      <path
        d='M24.9916 43.7331C24.8369 46.1465 22.8302 48.057 20.3767 48.057C17.9221 48.057 15.9154 46.1465 15.7607 43.7331C15.7542 43.6336 15.751 43.533 15.751 43.4323C15.751 42.5918 15.9749 41.8032 16.3665 41.1238C17.1659 39.7391 18.6621 38.8066 20.3767 38.8066C22.0902 38.8066 23.5863 39.7391 24.3847 41.1238C24.7774 41.8032 25.0013 42.5918 25.0013 43.4323C25.0013 43.533 24.9981 43.6336 24.9916 43.7331ZM22.2741 43.4323C22.2741 42.3863 21.4228 41.5349 20.3767 41.5349C19.3295 41.5349 18.4792 42.3863 18.4792 43.4323C18.4792 44.4784 19.3295 45.3298 20.3767 45.3298C21.4228 45.3298 22.2741 44.4784 22.2741 43.4323Z'
        fill='#313842'
      />
      <path
        d='M20.3777 41.5352C21.4238 41.5352 22.2751 42.3865 22.2751 43.4326C22.2751 44.4787 21.4238 45.3301 20.3777 45.3301C19.3305 45.3301 18.4802 44.4787 18.4802 43.4326C18.4802 42.3865 19.3305 41.5352 20.3777 41.5352Z'
        fill='#EFEFEF'
      />
      <path
        d='M14.5616 43.4326C14.5616 43.5332 14.5648 43.6338 14.5702 43.7333H11.3046C10.5841 43.7333 10 43.1492 10 42.4287C10 41.7081 10.5841 41.124 11.3046 41.124H15.0386C14.7249 41.8488 14.5616 42.6353 14.5616 43.4326Z'
        fill='#313842'
      />
      <path
        d='M37.7185 34.8411H36.0136C35.6552 34.8411 35.3645 34.5505 35.3645 34.192C35.3645 33.8335 35.6552 33.543 36.0136 33.543H37.7185C38.0769 33.543 38.3675 33.8335 38.3675 34.192C38.3675 34.5505 38.077 34.8411 37.7185 34.8411Z'
        fill='#313842'
      />
      <path
        d='M26.0117 34.8411H24.3068C23.9484 34.8411 23.6577 34.5505 23.6577 34.192C23.6577 33.8335 23.9484 33.543 24.3068 33.543H26.0117C26.3701 33.543 26.6607 33.8335 26.6607 34.192C26.6607 34.5505 26.3701 34.8411 26.0117 34.8411Z'
        fill='#313842'
      />
      <path
        d='M59.0592 37.8028H57.7557C56.4413 37.8028 55.3757 36.7372 55.3757 35.4229H58.633C58.9911 36.2055 59.1533 36.9763 59.0592 37.8028Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M31.6378 24.5693V31.2807H30.8773L24.167 24.5693H31.6378Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M43.5496 31.2801H39.9862L33.2607 24.5547H37.6766C38.7465 24.5547 39.7612 25.1215 40.3215 26.0335L43.5496 31.2801Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M22.5453 24.9707V30.2823L19.9133 27.6503C20.4131 26.4365 21.3694 25.4824 22.5453 24.9707Z'
        fill='#313842'
      />
    </SvgIcon>
  );
}

CarIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default CarIcon;
