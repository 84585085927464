import * as React from 'react';
// @mui
import { Button, ButtonProps, IconButton, IconButtonProps, Tooltip } from '@mui/material';
// @local
import useBreakpoints from '../hooks/use-breakpoints';

export type TResponsiveButtonProps = {
  title: string;
  label: string;
  onClick?: () => void;
  icon: React.ReactNode;
  buttonProps: ButtonProps;
  iconButtonProps: IconButtonProps;
};

const ResponsiveButton = ({
  icon,
  title,
  label,
  onClick,
  buttonProps,
  iconButtonProps
}: TResponsiveButtonProps) => {
  const { smUp } = useBreakpoints();

  return smUp ? (
    <Button {...buttonProps} startIcon={icon} onClick={onClick}>
      {label}
    </Button>
  ) : (
    <Tooltip title={title}>
      <IconButton {...iconButtonProps} onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default ResponsiveButton;
