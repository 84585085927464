import * as React from 'react';
// @mui
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { IoCloseOutline } from 'react-icons/io5';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TransitionProps } from '@mui/material/transitions';
// @custom
import { Heading4 } from '../typography';
// @local
import DocumentViewer from './document-viewer';
import { Dialog, DialogHeader } from './styles';

type DocumentViewerModalProps = {
  id: string;
  title: string;
  open: boolean;
  documentUrl?: string;
  hideCancelButton?: boolean;
  onClose: (props?: object) => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DocumentViewerModal = ({
  id,
  title,
  open,
  onClose,
  documentUrl,
  hideCancelButton = false
}: DocumentViewerModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth='xl'
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby={id}
    >
      <DialogHeader id={id}>
        <Heading4>{title}</Heading4>
        {!hideCancelButton && (
          <IconButton size='small' onClick={onClose}>
            <IoCloseOutline size={30} />
          </IconButton>
        )}
      </DialogHeader>
      <Box mt={1}>
        <Divider />
      </Box>
      <DocumentViewer documentUrl={documentUrl} alt={title} />
    </Dialog>
  );
};

export default DocumentViewerModal;
