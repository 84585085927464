const CategoryIcon = ({
  src,
  name,
  size = 50
}: {
  src: string;
  name: string;
  size?: number;
}) => {
  return <img src={src} alt={`${name} icon`} width={size} height={size} />;
};

export default CategoryIcon;
