import { config } from '@ld/core';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const { isDebugEnv, isLocalEnv, recaptchaKey, appCheckDebugToken, firebaseAppConfig } =
  config;

const app = initializeApp(firebaseAppConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
if (recaptchaKey) {
  if ((isDebugEnv || isLocalEnv) && appCheckDebugToken) {
    (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckDebugToken; // eslint-disable-line no-restricted-globals
  }
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(recaptchaKey),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
}

if (isLocalEnv) {
  connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
  const functions = getFunctions(app, 'europe-west6');
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(getStorage(), 'localhost', 9199);
}
