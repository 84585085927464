import { createContext } from 'react';
import { IAdvisorRead } from '@ld/utils';

const UserContext = createContext<{
  user: IAdvisorRead | null;
  updateUser: (data: IAdvisorRead) => void;
  setUser: (data: IAdvisorRead | null) => void;
}>({ user: null, updateUser: () => null, setUser: () => null });

export default UserContext;
