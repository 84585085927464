import { SvgIcon, SvgIconProps } from '@mui/material';

function GiftIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M20 8.125V12.5H24.375C25.2403 12.5 26.0862 12.2434 26.8056 11.7627C27.5251 11.282 28.0858 10.5987 28.417 9.79924C28.7481 8.99982 28.8347 8.12015 28.6659 7.27148C28.4971 6.42282 28.0804 5.64326 27.4686 5.03141C26.8567 4.41956 26.0772 4.00288 25.2285 3.83407C24.3799 3.66526 23.5002 3.7519 22.7008 4.08303C21.9013 4.41416 21.2181 4.97492 20.7373 5.69438C20.2566 6.41385 20 7.25971 20 8.125V8.125Z'
        stroke='#EE6338'
        fill='#ffffff'
        strokeWidth='2.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M20 8.125C20 9.33281 20 12.5 20 12.5H15.625C14.7597 12.5 13.9138 12.2434 13.1944 11.7627C12.4749 11.282 11.9142 10.5987 11.583 9.79924C11.2519 8.99982 11.1653 8.12015 11.3341 7.27148C11.5029 6.42282 11.9196 5.64326 12.5314 5.03141C13.1433 4.41956 13.9228 4.00288 14.7715 3.83407C15.6201 3.66526 16.4998 3.7519 17.2992 4.08303C18.0987 4.41416 18.782 4.97492 19.2627 5.69438C19.7434 6.41385 20 7.25971 20 8.125V8.125Z'
        stroke='#EE6338'
        fill='#ffffff'
        strokeWidth='2.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M32.5 12.5H7.5C6.11929 12.5 5 13.6193 5 15V18.75C5 20.1307 6.11929 21.25 7.5 21.25H32.5C33.8807 21.25 35 20.1307 35 18.75V15C35 13.6193 33.8807 12.5 32.5 12.5Z'
        stroke='#EE6338'
        fill='#ffffff'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32.5 21.25V32.5C32.5 33.4946 32.1049 34.4484 31.4017 35.1517C30.6984 35.8549 29.7446 36.25 28.75 36.25H11.25C10.2554 36.25 9.30161 35.8549 8.59835 35.1517C7.89509 34.4484 7.5 33.4946 7.5 32.5V21.25'
        stroke='#EE6338'
        fill='#ffffff'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 12.5V36.25'
        stroke='#EE6338'
        fill='#ffffff'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}

GiftIcon.defaultProps = {
  viewBox: '0 0 50 50'
};

export default GiftIcon;
