import { Timestamp } from 'firebase/firestore';

import { IEventAtRead, IEventAtWrite } from './event';
import { TNullableString } from './base';

export enum ENotificationSourceType {
  PERSON = 'person',
  THIRD_PARTY = 'third_party',
  INTERNAL_SYSTEM = 'internal_system'
}

export enum ENotificationType {
  DOCUMENT_UPLOAD_REQUEST = 'document_upload_request',
  EXPIRING_DOCUMENT = 'expiring_document',
  EXPIRED_DOCUMENT = 'expired_document'
}

type TNotificationSource = {
  uid?: TNullableString;
  name?: TNullableString;
  photoUrl?: TNullableString;
  type: ENotificationSourceType;
};

type TNotificationDocument = {
  // document
  url: string;
  type: string;
  name: string; // document name
  fileName: string;
  fileSize: number;
  displayName?: string; // document display name
  expiresAt?: Timestamp;
  // category
  categoryId: string;
  categoryName: string;
  categoryIcon: string;
  // entity
  entityId: string;
  entityName: string;
};

export interface INotificationWrite {
  read: boolean;
  profileId: string;
  type: ENotificationType;
  source: TNotificationSource;
  data: TNotificationDocument;
  created: IEventAtWrite;
  updated: IEventAtWrite;
}
export interface INotificationRead extends INotificationWrite {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
}
