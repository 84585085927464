import { SvgIcon, SvgIconProps } from '@mui/material';

function EmploymentIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M45.2539 22.9737H42.3242V17.9397C41.0182 17.1931 37.9598 15.6495 35 15.6495C32.0245 15.6495 28.9761 17.1916 27.6758 17.9384V22.9738H24.7461V16.3304C27.7505 14.3474 31.4275 12.7197 35 12.7197C38.5671 12.7197 42.2426 14.3428 45.2539 16.3304V22.9737Z'
        fill='#313842'
      />
      <path
        d='M42.3242 17.9397V22.9737H45.2539V16.3304C42.2427 14.3427 38.567 12.7197 35 12.7197V15.6494C37.9598 15.6495 41.0182 17.193 42.3242 17.9397Z'
        fill='#272B31'
      />
      <path
        d='M57.0703 53.7352H12.9297V37.2041C14.6562 38.099 15.5472 39.0866 17.3242 39.0866H52.6758C54.4531 39.0866 55.3494 38.096 57.0703 37.2041V53.7352Z'
        fill='#272B31'
      />
      <path
        d='M35 39.0867V53.7352H57.0703V37.2041C55.3494 38.096 54.4532 39.0866 52.6758 39.0866H35V39.0867Z'
        fill='#272B31'
      />
      <path
        d='M43.0273 21.5078H35H26.9727H10V34.6914C10 38.8779 13.4489 42.0156 17.3242 42.0156H52.6758C56.5319 42.0156 60 38.9021 60 34.6914V21.5078H43.0273Z'
        fill='#313842'
      />
      <path
        d='M52.6758 42.0156C56.5319 42.0156 60 38.902 60 34.6914V21.5078H43.0273L35 24.6133V42.0156H52.6758Z'
        fill='#313842'
      />
      <path d='M18.7891 36.1582H21.7188V44.9473H18.7891V36.1582Z' fill='#EFEFEF' />
      <path d='M48.2812 36.1582H51.2109V44.9473H48.2812V36.1582Z' fill='#EFEFEF' />
      <path
        d='M40.9998 50.9922L34.9998 59L28.9998 50.9922L31.395 30.1527L34.9998 27L38.6045 30.1527L40.9998 50.9922Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M40.9998 50.9922L38.6045 30.1527L34.9998 27V59L40.9998 50.9922Z'
        fill='#313842'
      />
      <path
        d='M42.02 21.5078L38.8921 30.4727H31.0978L27.97 21.5078H42.02Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M42.0171 21.5078L38.8902 30.4727H31.0981L27.9712 21.5078H42.0171Z'
        fill='#313842'
      />
      <path
        opacity='0.1'
        d='M34.9946 30.4727H38.8918L42.0196 21.5078H34.9946V30.4727Z'
        fill='#313842'
      />
    </SvgIcon>
  );
}

EmploymentIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default EmploymentIcon;
