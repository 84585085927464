import * as React from 'react';

// @ld
import { AbilityContext, buildAbilityFor, TAccessAbility } from '@ld/core';
import { EProfileRole, IProfileRoleRead, getProfileRolesPath } from '@ld/utils';

// @custom
import useUser from 'hooks/use-user';
import useProfile from 'hooks/use-profile';
import useDocListener from 'api/utils/use-doc-listener';

const ProfileRoleContext = React.createContext<{
  role?: IProfileRoleRead;
  ability?: TAccessAbility;
  isError?: boolean;
}>({});

export const ProfileRoleProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const { profile } = useProfile();
  const [ability, setAbility] = React.useState<TAccessAbility>(buildAbilityFor('none'));

  const roleId = user?.uid || '';
  const profileId = profile?.id || '';
  const rolePath = getProfileRolesPath(profileId);

  const { data: role, isError } = useDocListener<IProfileRoleRead>({
    id: roleId,
    collection: rolePath,
    enabled: !!profile?.id && !!user?.uid
  });

  React.useEffect(() => {
    if (profile && user) {
      let newAbility = buildAbilityFor(role?.role || EProfileRole.NONE);
      if (profile?.ownerUserId === user.uid) {
        newAbility = buildAbilityFor(EProfileRole.OWNER);
      }
      setAbility(newAbility);
    }
  }, [profile, user, role]);

  return (
    <ProfileRoleContext.Provider value={{ ability, role, isError }}>
      <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    </ProfileRoleContext.Provider>
  );
};

ProfileRoleContext.displayName = 'ProfileRoleContext';

export default ProfileRoleContext;
