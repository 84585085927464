// @ld
import { IProfileCategoryRead } from '@ld/utils';
// @custom
import CategoryIcon from '../category-icon';
// @local
import { Text, Title, IconWrapper, StyledFolder, LinearProgress } from './styles';

type CategoryFolderProps = {
  to: string;
  category: IProfileCategoryRead;
};

const CategoryFolderFooter = ({ category }: CategoryFolderProps) => {
  if (category?.entity) {
    const addedEntitiesCount = category.addedEntities?.length || 0;
    return (
      <Text>
        <span>{addedEntitiesCount}</span>{' '}
        {addedEntitiesCount === 1
          ? category.entity.displayName
          : category.entity.pluralDisplayName}{' '}
        added
      </Text>
    );
  }
  const uploadedDocuments = category.uploadedDocuments || {};
  const uploadedDocumentsCount = Object.keys(uploadedDocuments).filter(
    (type) => (uploadedDocuments[type].length || 0) > 0
  ).length;
  const goal = Object.values(category.documentsConfig || {}).filter((val) => val).length;
  const progress = {
    documentsText:
      uploadedDocumentsCount <= goal
        ? `${uploadedDocumentsCount} / ${goal}`
        : `${goal} / ${goal}`,
    percentage:
      uploadedDocumentsCount <= goal ? (uploadedDocumentsCount / goal) * 100 : 100
  };

  if (goal !== 0) {
    return (
      <div>
        <Text>
          <span>{progress.documentsText}</span> documents uploaded
        </Text>
        {goal !== 0 && (
          <LinearProgress variant='determinate' value={progress.percentage} />
        )}
      </div>
    );
  }

  return null;
};

const CategoryFolder = (props: CategoryFolderProps) => {
  const { to, category } = props;
  return (
    <StyledFolder to={to}>
      {category?.id && (
        <IconWrapper>
          <CategoryIcon src={category.iconURL} name={category.name} />
        </IconWrapper>
      )}
      <Title>{category?.name}</Title>
      <CategoryFolderFooter {...props} />
    </StyledFolder>
  );
};

export default CategoryFolder;
