import * as React from 'react';

type SkeletonListProps = {
  id: string;
  count: number;
  skeleton: React.ElementType;
};

const getArrayOfLength = (length: number) => new Array(length).fill(1);

const SkeletonList = ({ id, count, skeleton: SkeletonComponent }: SkeletonListProps) => (
  <>
    {getArrayOfLength(count).map((_, index) => (
      <SkeletonComponent key={`${id}-${index}`} />
    ))}
  </>
);

export default SkeletonList;
