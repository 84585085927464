// @constant
import colors from './constants/colors';
import options from './constants/options';
import * as constants from './constants/app';
// @utils
import { clamp } from './utilities/numbers';
import {
  isEmail,
  isRequired,
  validateAll,
  isNonEmptyArray,
  isRequiredArray,
  isRequiredEmail
} from './utilities/validation';
import {
  formatDate,
  formatBytes,
  toKebabCase,
  kebabToNormalCase,
  snakeToNormalCase,
  strArrayToHumanReadableString
} from './utilities/format';
import {
  TASKS,
  USERS,
  PROFILES,
  CONTACTS,
  DOCUMENTS,
  SETTINGS,
  CATEGORIES,
  PROMO_CODES,
  SHARE_LINKS,
  VAULT_CONFIG,
  VAULT_STATUS,
  NOTIFICATIONS,
  SUBSCRIPTIONS,
  ORGANISATIONS,
  PAYMENT_METHODS,
  PROFILE_INVITES,
  EMERGENCY_CONTACTS,

  // app category documents
  getCategoryDocumentsPath,
  // users
  getUserSettingsPath,
  getUserSubscriptionsPath,
  getUserPaymentMethodsPath,
  getUserEmergencyContactsPath,
  // profiles
  getProfileERSGapPath,
  getProfileRolesPath,
  getProfileSummaryPath,
  getProfileDetailsPath,
  getProfileContactsPath,
  getProfileEntitiesPath,
  getProfileDocumentsPath,
  getProfileCategoriesPath,
  getProfileNotificationsPath,
  getProfileCategoriesDocPath,
  getProfileSummaryCollectionPath,
  getProfileContactsUniqueEmailsPath
} from './utilities/collections';
import getArray from './utilities/get-array';
import getFilePath from './utilities/get-file-path';
import pluralize, { docPluralize } from './utilities/pluralize';
import { estateReadinessGapCount } from './utilities/estate-readiness';
import { getEntityName, getEntityDocumentVaultConfigKey } from './utilities/entity';
import getProfileAccessPermissions from './utilities/get-profile-access-permissions';
import { prepareDocumentData, toEditableDocument } from './utilities/documents';
// @types
import { IEventAtWrite, IEventAtRead } from './types/event';
import { IUserRead, IAdvisorRead, EAdvisorRole } from './types/users';
import { IEntityRead, IEntityWrite, IEntityDetails } from './types/profiles/entities';
import {
  TPaymentMethod,
  TSubscriptionRead,
  ESubscriptionStatus
} from './types/payment-methods';
import { TSharedDocument, IShareLinkWrite, IShareLinkRead } from './types/sharing';
import { IProfileRolesLogRead, IProfileRolesLogWrite } from './types/profile-roles-log';
import {
  TDownloadPack,
  IProfileDocumentRead,
  IProfileDocumentWrite
} from './types/profiles/documents';
import {
  TAcceptedRead,
  TAcceptedWrite,
  IRejectedRead,
  TRejectedWrite,
  IProfileInviteRead,
  IProfileInviteWrite,
  EProfileInviteStatus
} from './types/profile-invites';
import { TGenericObject, TNullableString } from './types/base';
import {
  TAccessLevel,
  EAccessLevel,
  EBasicAccessLevel,
  TBasicAccessLevel
} from './types/access';
import { IProfileRoleRead, IProfileRoleWrite } from './types/profile-access';
import {
  TUploadFileData,
  TUploadFileState,
  EUploadFileStatus,
  TUploadFileOptions,
  TUploadFileSuccess,
  TUploadFileProcessing
} from './types/upload';
import {
  ECategoryType,
  IDocumentRead,
  IDocumentWrite,
  ICategoryRead,
  ICategoryWrite,
  IProfileCategoryRead,
  IProfileCategoryWrite
} from './types/profiles/categories';
import {
  ENotificationType,
  INotificationRead,
  INotificationWrite,
  ENotificationSourceType
} from './types/notifications';
import {
  IEmergencyContactRead,
  IEmergencyContactWrite,
  IEmergencyContactEmailWrite,
  TManageEmergencyContactType
} from './types/emergency-contacts';
import {
  EContactType,
  IContactRead,
  IContactWrite,
  IContactUniqueEmailWrite,
  EContactRelationships
} from './types/contacts';
import {
  IOrganisationWrite,
  IOrganisationRead,
  IPublicOrganisationWrite,
  IPublicOrganisationRead,
  EOrganisationStatus
} from './types/organisations';
import {
  IKYCDocs,
  TProfileRole,
  IProfileRead,
  EProfileRole,
  EProfileType,
  IProfileWrite,
  EProfileStatus,
  IExpiringDocument,
  IProfileSummaryRead,
  ILastWillAndTestament,
  IProfileSummaryBaseDocument
} from './types/profiles';
import {
  EMaritalStatus,
  ETypesOfMarriages,
  IProfileDetailsRead,
  IProfileDetailsWrite
} from './types/profile-details';
import { ISettingsRead, ISettingsWrite } from './types/settings';
import { IPromoCodeWrite, IPromoCodeRead } from './types/promo-codes';
export {
  // @constants
  colors,
  options,
  constants,
  // @collections
  TASKS,
  USERS,
  CONTACTS,
  PROFILES,
  SETTINGS,
  DOCUMENTS,
  CATEGORIES,
  PROMO_CODES,
  SHARE_LINKS,
  VAULT_CONFIG,
  VAULT_STATUS,
  SUBSCRIPTIONS,
  ORGANISATIONS,
  NOTIFICATIONS,
  PAYMENT_METHODS,
  PROFILE_INVITES,
  EMERGENCY_CONTACTS,
  getUserSettingsPath,
  getProfileRolesPath,
  getProfileERSGapPath,
  getProfileSummaryPath,
  getProfileDetailsPath,
  getProfileContactsPath,
  getProfileEntitiesPath,
  getProfileDocumentsPath,
  getUserSubscriptionsPath,
  getProfileCategoriesPath,
  getCategoryDocumentsPath,
  getUserPaymentMethodsPath,
  getProfileNotificationsPath,
  getProfileCategoriesDocPath,
  getUserEmergencyContactsPath,
  getProfileSummaryCollectionPath,
  getProfileContactsUniqueEmailsPath,
  // @functions
  clamp,
  isEmail,
  getArray,
  pluralize,
  isRequired,
  formatDate,
  getFilePath,
  formatBytes,
  validateAll,
  toKebabCase,
  docPluralize,
  getEntityName,
  isNonEmptyArray,
  isRequiredArray,
  isRequiredEmail,
  snakeToNormalCase,
  kebabToNormalCase,
  estateReadinessGapCount,
  strArrayToHumanReadableString,
  getProfileAccessPermissions,
  getEntityDocumentVaultConfigKey,
  prepareDocumentData,
  toEditableDocument,
  // @types
  IKYCDocs,
  TNullableString,
  TDownloadPack,
  IShareLinkRead,
  IShareLinkWrite,
  TSharedDocument,
  IProfileDocumentRead,
  IProfileDocumentWrite,
  IExpiringDocument,
  IProfileSummaryRead,
  ILastWillAndTestament,
  IProfileSummaryBaseDocument,
  TUploadFileData,
  TUploadFileState,
  EUploadFileStatus,
  TUploadFileSuccess,
  TUploadFileOptions,
  TUploadFileProcessing,
  TPaymentMethod,
  TSubscriptionRead,
  ESubscriptionStatus,
  ECategoryType,
  IDocumentRead,
  IDocumentWrite,
  ICategoryRead,
  ICategoryWrite,
  IProfileCategoryRead,
  IProfileCategoryWrite,
  IEmergencyContactRead,
  IEmergencyContactWrite,
  IContactUniqueEmailWrite,
  IEmergencyContactEmailWrite,
  TManageEmergencyContactType,
  EContactType,
  IContactRead,
  IContactWrite,
  IEntityRead,
  IEntityWrite,
  IEntityDetails,
  TGenericObject,
  TAccessLevel,
  EAccessLevel,
  EBasicAccessLevel,
  TBasicAccessLevel,
  IProfileRoleRead,
  IProfileRoleWrite,
  IUserRead,
  IAdvisorRead,
  EAdvisorRole,
  ENotificationType,
  INotificationRead,
  INotificationWrite,
  ENotificationSourceType,
  IOrganisationWrite,
  IOrganisationRead,
  IPublicOrganisationWrite,
  IPublicOrganisationRead,
  EOrganisationStatus,
  IProfileDetailsRead,
  IProfileDetailsWrite,
  EProfileStatus,
  EProfileRole,
  TProfileRole,
  IProfileRead,
  IProfileWrite,
  EProfileType,
  EContactRelationships,
  EMaritalStatus,
  ETypesOfMarriages,
  TAcceptedRead,
  TAcceptedWrite,
  IRejectedRead,
  TRejectedWrite,
  IProfileInviteRead,
  IProfileInviteWrite,
  EProfileInviteStatus,
  IEventAtWrite,
  IEventAtRead,
  IProfileRolesLogRead,
  IProfileRolesLogWrite,
  IPromoCodeWrite,
  IPromoCodeRead,
  ISettingsRead,
  ISettingsWrite
};
