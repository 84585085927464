import { useState, useEffect } from 'react';
import {
  doc,
  onSnapshot,
  getFirestore,
  DocumentData,
  FirestoreError
} from 'firebase/firestore';

type UseDocListenerOptions<TData> = {
  id?: string;
  enabled?: boolean;
  collection: string;
  error?: FirestoreError;
  onError?: (error: Error) => void;
  onChange?: (data: TData) => void;
};

enum EDocListenerStatus {
  IDLE = 'idle',
  ERROR = 'error',
  ACTIVE = 'active',
  LOADING = 'loading'
}

const useDocListener = <TDataRead = unknown>({
  id,
  onError,
  onChange,
  collection,
  enabled = true
}: UseDocListenerOptions<TDataRead>) => {
  const [data, setData] = useState<TDataRead>();
  const [error, setError] = useState<FirestoreError | undefined>();
  const [status, setStatus] = useState<EDocListenerStatus>(EDocListenerStatus.IDLE);

  useEffect(() => {
    if (enabled && id) {
      const db = getFirestore();
      const docRef = doc(db, collection, id);
      setData(undefined);
      setStatus(EDocListenerStatus.LOADING);
      const unsubscribe = onSnapshot(
        docRef,
        (doc) => {
          const docData = { id: doc.id, ...(doc.data() as DocumentData) } as TDataRead;
          setData(docData);
          onChange?.(docData);
          setStatus(EDocListenerStatus.ACTIVE);
        },
        (error) => {
          console.error(error);
          setError(error);
          onError?.(error);
          setStatus(EDocListenerStatus.ERROR);
        }
      );
      return () => unsubscribe();
    }
    return;
  }, [enabled, id]);

  return {
    data,
    error,
    isIdle: status === EDocListenerStatus.IDLE,
    isError: status === EDocListenerStatus.ERROR,
    isActive: status === EDocListenerStatus.ACTIVE,
    isLoading: status === EDocListenerStatus.LOADING
  };
};

export default useDocListener;
