import * as React from 'react';
import {
  IoDocumentTextOutline,
  IoCheckmarkCircleOutline,
  IoInformationCircleOutline
} from 'react-icons/io5';
// @mui
import Box from '@mui/material/Box';
// @ld
import { getFilePath, EUploadFileStatus, TUploadFileSuccess } from '@ld/utils';
// @custom
import useUploadFile from 'hooks/use-upload-file';
// @local
import {
  Text,
  Button,
  LinearProgress,
  CircularProgress,
  StatusIconWrapper
} from './styles';
import { TDocumentUpload } from '../types';

type FileUploaderProps = {
  enabled?: boolean;
  minimized?: boolean;
  data: TDocumentUpload;
  onSuccess?: (data: TUploadFileSuccess) => void;
};

// 'paused'
const StatusIcon = ({ status }: { status: EUploadFileStatus }) => {
  switch (status) {
    case EUploadFileStatus.IDLE:
      return <CircularProgress size={24} variant='indeterminate' color='primary' />;
    case EUploadFileStatus.UPLOADING:
      return <IoDocumentTextOutline />;
    case EUploadFileStatus.ERROR:
      return <IoInformationCircleOutline />;
    case EUploadFileStatus.SUCCESS:
      return <IoCheckmarkCircleOutline />;
    default:
      return null;
  }
};

const FileUploader = ({
  data,
  onSuccess,
  enabled = false,
  minimized = false
}: FileUploaderProps) => {
  const { data: uploadData, document } = data;
  const { status, progress } = useUploadFile(
    {
      path: getFilePath(),
      file: data.file
    },
    {
      enabled,
      onSuccess: (fileData) => {
        onSuccess?.(fileData);
      }
    }
  );
  /**
   * This is to make sure that we don't logout a user while they are
   * busy uploading documents. It keeps the user as active by
   * pushing the expire time in the app idle timer component.
   */
  React.useEffect(() => {
    const interval = window.setInterval(() => {
      const event = new Event('uploading');
      window.dispatchEvent(event);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (minimized) return null;

  return (
    <Box my={1} display='flex' alignItems='center'>
      <StatusIconWrapper $variant={status}>
        <StatusIcon status={status} />
      </StatusIconWrapper>
      <Box ml={2} mb={1} flex={1} display='flex' maxWidth='80%' flexDirection='column'>
        <Text $noMargin={status === 'error'}>
          {uploadData.displayName || document.name}
        </Text>
        {status === 'error' ? (
          <Button variant='text' size='small'>
            Retry
          </Button>
        ) : (
          <LinearProgress variant='determinate' value={progress} />
        )}
      </Box>
    </Box>
  );
};

export default FileUploader;
